<template>
  <Layout>
    <main class="pt-5 pb-3">
      <div class="container">
        <div class="card memberStep">
          <div v-if="isLoading" class="preloader-container">
            <div class="preloader"></div>
          </div>
          <div v-else>
            <div class="card-body">
              <div class="row position-relative">
                <!-- <div class="col-3 pt-3 " v-if="check_type!='verification officer'">
                  <button
                    @click="showModal = true"
                    ref="anotherButton"
                    type="button"
                    class="btn btn-theme btn-custom-wdth"
                  >
                    Feedback
                  </button>
                </div> -->
                <div class="col-12">
                  <h2 class="mainTitleSize pt-3">Membership Application</h2>
                </div>
                <div class="floatbtns pt-3">
                  <button @click="downloadPDF()" ref="anotherButton" type="button"
                    class="btn btn-theme btn-custom-wdth">
                    Download as PDF
                  </button>
                </div>
              </div>

              <!-- <b-button
                :to="`/pdf_data/${app_id}`"
                class="btn-sm btn-theme-dark"
                title="pdf chek list "
              >
                pdf Application
              </b-button> -->
              <div v-if="!showRadio" class="">
                <!-- <div class="d-block mt-5 mb-3">
                    <h2 class="inner-title-step">Risk Category/ Rating:</h2>
                    <div class="row gx-2">
                      <div class="col-md-4 mb-3 mb-md-0">
                        <label for="categoryLow" class="w-100 b-0 p-relative bg-theme-dark border pe-3 labelStyleRadioCstm">
                          <input type="radio" v-model="riskInput" name="categoryRisk" class="inputRadioCstm d-none"
                            id="categoryLow" value="low" disabled />
                          <span class="radioCircleIcon">
                            <i class="mdi mdi-circle-outline text-white font-size-20"></i>
                            <i class="mdi mdi-checkbox-marked-circle text-white theme-check font-size-20"></i>
                          </span>
                          <h4 class="font-size-14 text-white mb-0 ms-2">LOW</h4>
                        </label>
                      </div>
                      <div class="col-md-4 mb-3 mb-md-0">
                        <label for="categoryMedium"
                          class="w-100 mb-0 p-relative bg-theme-dark border pe-3 labelStyleRadioCstm">
                          <input type="radio" v-model="riskInput" name="categoryRisk" class="inputRadioCstm d-none"
                            id="categoryMedium" value="medium" disabled />
                          <span class="radioCircleIcon">
                            <i class="mdi mdi-circle-outline text-white font-size-20"></i>
                            <i class="mdi mdi-checkbox-marked-circle text-white theme-check font-size-20"></i>
                          </span>
                          <h4 class="font-size-14 text-white mb-0 ms-2">
                            MEDIUM
                          </h4>
                        </label>
                      </div>
                      <div class="col-md-4 mb-3 mb-md-0">
                        <label for="categoryHigh"
                          class="w-100 mb-0 p-relative bg-theme-dark border pe-3 labelStyleRadioCstm">
                          <input type="radio" v-model="riskInput" name="categoryRisk" class="inputRadioCstm d-none"
                            id="categoryHigh" value="high" disabled />
                          <span class="radioCircleIcon">
                            <i class="mdi mdi-circle-outline text-white font-size-20"></i>
                            <i class="mdi mdi-checkbox-marked-circle text-white theme-check font-size-20"></i>
                          </span>
                          <h4 class="font-size-14 text-white mb-0 ms-2">HIGH</h4>
                        </label>
                      </div>
                    </div>
                  </div> -->
                <br />
                <br />
                <div class="text-end">
                  <button @click="showRadio = true" ref="anotherButton" type="button"
                    class="btn btn-theme btn-custom-wdth">
                    Finish
                  </button>
                  <!-- <button @click="submitDataInitial" type="button" class="btn btn-theme btn-custom-wdth">
                      Next
                    </button> -->
                </div>
              </div>
              <template v-if="showRadio">
                <form-wizard @on-complete="onComplete" ref="myWizard" :start-index="startIndex"
                  next-button-text="Finish" step-size="xs" color="#f48226" @saveFormData="saveFormData"
                  id="pdf_data_form">
                  <tab-content title="">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="bg-theme p-3">
                          <div class="row">
                            <div class="col-md-6">
                              <label for="adultRadio" class="mb-0 p-relative border w-100 labelStyleRadioCstm">
                                <input type="radio" v-model="radioSelected1" value="adult" name="radioAdult"
                                  class="inputRadioCstm d-none" id="adultRadio" disabled />
                                <span class="radioCircleIcon">
                                  <i class="mdi mdi-circle-outline text-white font-size-20"></i>
                                  <i class="mdi mdi-checkbox-marked-circle text-white theme-check font-size-20"></i>
                                </span>
                                <h4 class="font-size-14 text-white mb-0 ms-2">
                                  Adult (Any person 18 years and over at the
                                  time of application is considered an adult)
                                </h4>
                              </label>
                            </div>
                            <div class="col-md-6">
                              <label for="minorRadio" class="mb-0 p-relative border w-100 labelStyleRadioCstm">
                                <input type="radio" v-model="radioSelected1" value="minor" name="radioAdult"
                                  class="inputRadioCstm d-none" id="minorRadio" disabled />
                                <span class="radioCircleIcon">
                                  <i class="mdi mdi-circle-outline text-white font-size-20"></i>
                                  <i class="mdi mdi-checkbox-marked-circle text-white theme-check font-size-20"></i>
                                </span>
                                <h4 class="font-size-14 text-white mb-0 ms-2">
                                  Minor (Any person under the age of 18 at the
                                  time of application is considered a minor)
                                </h4>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-lg-6">
                        <div class="bg-theme p-3">
                          <div class="row">
                            <div class="col-md-6">
                              <label for="pepRadio" class="mb-0 p-relative border w-100 labelStyleRadioCstm">
                                <input type="checkbox" v-model="checkFeildInput1" value="pep" name="radioPep"
                                  class="inputRadioCstm d-none" id="pepRadio" disabled />
                                <span class="radioCircleIcon">
                                  <i class="mdi mdi-checkbox-blank-outline text-white font-size-22"></i>
                                  <i class="mdi mdi-check-box-outline text-white theme-check font-size-22"></i>
                                </span>
                                <h4 class="font-size-14 text-white mb-0 ms-2">
                                  PEP
                                </h4>
                              </label>
                            </div>
                            <div class="col-md-6">
                              <label for="fatcaRadio" class="mb-0 p-relative border w-100 labelStyleRadioCstm">
                                <input type="checkbox" v-model="checkFeildInput2" value="fatca" name="radioPep"
                                  class="inputRadioCstm d-none" id="fatcaRadio" disabled />
                                <span class="radioCircleIcon">
                                  <i class="mdi mdi-checkbox-blank-outline text-white font-size-22"></i>
                                  <i class="mdi mdi-check-box-outline text-white theme-check font-size-22"></i>
                                </span>
                                <h4 class="font-size-14 text-white mb-0 ms-2">
                                  FATCA
                                </h4>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </tab-content>
                  <tab-content title="">
                    <div class="text-main-title">
                      <button type="button" class="btn btn-theme button-position" v-on:click="resetFields()">
                        <i class="mdi mdi-arrow-left"></i> Back
                      </button>
                      <h2 class="titleStepForm">Personal Information</h2>
                    </div>
                    <div class="main-from-group my-4">
                      <div class="row">
                        <div class="groupFormStyle mb-4">
                          <div class="col-lg-12 mb-3">
                            <div class="d-block">
                              <label class="font-size-14">Branch</label>
                              <input type="text" v-model="form.personal.branchName" class="form-control" :class="{
                                  'is-invalid':
                                    v$.form.personal.branchName.$error,
                                }" placeholder="Branch" disabled />
                              <div v-for="(personal, index) in v$.form.personal
                                  .branchName.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal.$message">{{
                                  personal.$message
                                  }}</span>
                              </div>
                            </div>
                          </div>
                          <hr>
                          <div class="col-12 ">
                            <div class="row">
                              <div class="col-12">
                                <h3 class="formMainHeading">Applicant</h3>
                              </div>
                              <div class="col-lg-3 col-md-6 mb-3">
                                <div class="d-block">
                                  <label class="font-size-14">Title</label>
                                  <input type="text" class="form-control" v-model="form.personal.title" :class="{
                                      'is-invalid':
                                        v$.form.personal.title.$error,
                                    }" placeholder="Title" disabled />
                                  <div v-for="(personal, index) in v$.form.personal
                                      .title.$errors" :key="index" class="invalid-feedback">
                                    <span v-if="personal.$message">{{
                                      personal.$message
                                      }}</span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 mb-3">
                                <div class="d-block">
                                  <label class="font-size-14">Surname</label>
                                  <input type="text" class="form-control" placeholder="Surname" :class="{
                                      'is-invalid':
                                        v$.form.personal.surname.$error,
                                    }" v-model="form.personal.surname" disabled />
                                  <div v-for="(personal, index) in v$.form.personal
                                      .surname.$errors" :key="index" class="invalid-feedback">
                                    <span v-if="personal.$message">{{
                                      personal.$message
                                      }}</span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 mb-3">
                                <div class="d-block">
                                  <label class="font-size-14">First Name</label>
                                  <input type="text" v-model="form.personal.firstName" class="form-control" :class="{
                                      'is-invalid':
                                        v$.form.personal.firstName.$error,
                                    }" placeholder="First Name" disabled />
                                  <div v-for="(personal, index) in v$.form.personal
                                      .firstName.$errors" :key="index" class="invalid-feedback">
                                    <span v-if="personal.$message">{{
                                      personal.$message
                                      }}</span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 mb-3">
                                <div class="d-block">
                                  <label class="font-size-14">Middle Name</label>
                                  <input type="text" v-model="form.personal.middleName" class="form-control" :class="{
                                      'is-invalid':
                                        v$.form.personal.middleName.$error,
                                    }" placeholder="Middle Name" disabled />
                                  <div v-for="(personal, index) in v$.form.personal
                                      .middleName.$errors" :key="index" class="invalid-feedback">
                                    <span v-if="personal.$message">{{
                                      personal.$message
                                      }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Home Address</label>
                            <textarea type="textarea" v-model="form.personal.address" class="form-control" :class="{
                                'is-invalid': v$.form.personal.address.$error,
                              }" rows="3" placeholder="Home Address..." disabled></textarea>
                            <div v-for="(personal, index) in v$.form.personal
                                .address.$errors" :key="index" class="invalid-feedback">
                              ``
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-lg-4 mb-3">
                            <div class="d-block">
                              <label class="font-size-14">Geo Code</label>
                              <input type="number" v-model="form.personal.geoCode" class="form-control" :class="{
                                'is-invalid': v$.form.personal.geoCode.$error,
                              }" placeholder="Geo Code" disabled />
                              <div v-for="(personal, index) in v$.form.personal
                                .geoCode.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal.$message">{{
                                  personal.$message
                                }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 mb-3">
                            <div class="d-block">
                              <label class="font-size-14">Mailing Address
                                <sup>(if different from above)</sup></label>
                              <textarea type="textarea" v-model="form.personal.mailingAddress" class="form-control" :class="{
                                'is-invalid':
                                  v$.form.personal.mailingAddress.$error,
                              }" rows="3" placeholder="Mailing Address..." disabled ></textarea>
                              <div v-for="(personal, index) in v$.form.personal
                                .mailingAddress.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal.$message">{{
                                  personal.$message
                                }}</span>
                              </div>
                            </div>
                          </div> -->
                        <div class="col-lg-6 col-xl-4 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Address Line #1 </label>
                            <textarea type="text" v-model="form.personal.address_line" class="form-control" rows="1"
                              placeholder="Address Line #1...	" :class="{
                                'is-invalid':
                                  v$.form.personal.address_line.$error,
                              }" disabled></textarea>

                            <div v-for="(personal1, index) in v$.form.personal
                                .address_line.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal1.$message">{{
                                personal1.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-xl-4 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Address Line #2 </label>
                            <textarea type="text" v-model="form.personal.address_line_two" class="form-control" rows="1"
                              placeholder="Address Line #2...	" :class="{
                                'is-invalid':
                                  v$.form.personal.address_line_two.$error,
                              }" disabled></textarea>

                            <div v-for="(personal, index) in v$.form.personal
                                .address_line_two.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-xl-4 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">City</label>
                            <input type="text" v-model="form.personal.city_name" class="form-control" rows="1"
                              placeholder="City...	" :class="{
                                'is-invalid': v$.form.personal.city_name.$error,
                              }" disabled />

                            <div v-for="(personal, index) in v$.form.personal
                                .city_name.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal1.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-xl-4 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">State / Prov</label>
                            <input type="text" v-model="form.personal.state_name" class="form-control" rows="1"
                              placeholder="State / Prov...	" :class="{
                                'is-invalid':
                                  v$.form.personal.state_name.$error,
                              }" disabled />

                            <div v-for="(personal, index) in v$.form.personal
                                .state_name.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-xl-4 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Zip Code</label>
                            <input type="number" v-model="form.personal.zip_code" class="form-control" rows="1"
                              placeholder="Zip...	" :class="{
                                'is-invalid': v$.form.personal.zip_code.$error,
                              }" disabled />

                            <div v-for="(personal, index) in v$.form.personal
                                .zip_code.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-xl-4 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Country</label>
                            <input type="text" v-model="form.personal.country_name" class="form-control" rows="1"
                              placeholder="Country...	" :class="{
                                'is-invalid':
                                  v$.form.personal.country_name.$error,
                              }" disabled />

                            <div v-for="(personal, index) in v$.form.personal
                                .country_name.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Date of Birth</label>
                            <input type="date" v-model="form.personal.dob" class="form-control" :class="{
                                'is-invalid': v$.form.personal.dob.$error,
                              }" placeholder="Title" disabled />
                            <div v-for="(personal, index) in v$.form.personal.dob
                                .$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 mb-3">
                          <h4 class="font-size-14">Gender</h4>
                          <div class="row">
                            <div class="col-auto mb-2">
                              <label for="maleGender" class="mb-0 w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2"
                                :class="{
                                  'is-invalid': v$.form.personal.gender.$error,
                                }">
                                <input type="radio" v-model="form.personal.gender" name="genderRadio"
                                  class="inputRadioCstm d-none" id="maleGender" value="male" disabled />
                                <span class="radioCircleIcon">
                                  <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                  <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                </span>
                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                  Male
                                </h4>
                              </label>
                              <div v-for="(personal, index) in v$.form.personal
                                  .gender.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal.$message">{{
                                  personal.$message
                                  }}</span>
                              </div>
                            </div>
                            <div class="col-auto mb-2">
                              <label for="femaleGender" class="mb-0 w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2"
                                :class="{
                                  'is-invalid': v$.form.personal.gender.$error,
                                }">
                                <input type="radio" v-model="form.personal.gender" name="genderRadio"
                                  class="inputRadioCstm d-none" id="femaleGender" value="female" disabled />
                                <span class="radioCircleIcon">
                                  <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                  <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                </span>
                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                  Female
                                </h4>
                              </label>
                              <div v-for="(personal, index) in v$.form.personal
                                  .gender.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal.$message">{{
                                  personal.$message
                                  }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">No. of Dependants</label>
                            <input type="number" v-model="form.personal.numOfDependents" class="form-control" :class="{
                                'is-invalid':
                                  v$.form.personal.numOfDependents.$error,
                              }" placeholder="No. of Dependants" disabled />
                            <div v-for="(personal, index) in v$.form.personal
                                .numOfDependents.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                            <!-- <div class="">
                                <label>
                                  <input type="file"  @change="onFileChange()"/>
                                </label>
                              </div> -->
                          </div>
                        </div>
                        <div class="col-lg-6 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Place of Birth</label>
                            <input type="text" v-model="form.personal.placeOfdob" class="form-control" :class="{
                                'is-invalid':
                                  v$.form.personal.placeOfdob.$error,
                              }" placeholder="Place of Birth" disabled />
                            <div v-for="(personal, index) in v$.form.personal
                                .placeOfdob.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Nationality</label>
                            <input type="text" v-model="form.personal.nationality" class="form-control" :class="{
                                'is-invalid':
                                  v$.form.personal.nationality.$error,
                              }" placeholder="Nationality" disabled />
                            <div v-for="(personal, index) in v$.form.personal
                                .nationality.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 mb-3">
                          <h4 class="font-size-14">Marital Status</h4>
                          <div class="row">
                            <div class="col-auto mb-2">
                              <label for="singleMat" class="mb-0 w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2"
                                :class="{
                                  'is-invalid':
                                    v$.form.personal.maritalStatus.$error,
                                }">
                                <input type="radio" v-model="form.personal.maritalStatus" value="single"
                                  name="materialRadio" class="inputRadioCstm d-none" id="singleMat" disabled />
                                <span class="radioCircleIcon">
                                  <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                  <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                </span>
                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                  Single
                                </h4>
                              </label>
                              <div v-for="(personal, index) in v$.form.personal
                                  .maritalStatus.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal.$message">{{
                                  personal.$message
                                  }}</span>
                              </div>
                            </div>
                            <div class="col-auto mb-2">
                              <label for="marriedMat" class="mb-0 w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2"
                                :class="{
                                  'is-invalid':
                                    v$.form.personal.maritalStatus.$error,
                                }">
                                <input type="radio" v-model="form.personal.maritalStatus" value="married"
                                  name="materialRadio" class="inputRadioCstm d-none" id="marriedMat" disabled />
                                <span class="radioCircleIcon">
                                  <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                  <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                </span>
                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                  Married
                                </h4>
                              </label>
                              <div v-for="(personal, index) in v$.form.personal
                                  .maritalStatus.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal.$message">{{
                                  personal.$message
                                  }}</span>
                              </div>
                            </div>
                            <div class="col-auto mb-2">
                              <label for="divorcedMat" class="mb-0 w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2"
                                :class="{
                                  'is-invalid':
                                    v$.form.personal.maritalStatus.$error,
                                }">
                                <input type="radio" v-model="form.personal.maritalStatus" value="divorced"
                                  name="materialRadio" class="inputRadioCstm d-none" id="divorcedMat" disabled />
                                <span class="radioCircleIcon">
                                  <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                  <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                </span>
                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                  Divorced
                                </h4>
                              </label>
                              <div v-for="(personal, index) in v$.form.personal
                                  .maritalStatus.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal.$message">{{
                                  personal.$message
                                  }}</span>
                              </div>
                            </div>
                            <div class="col-auto mb-2">
                              <label for="widowedMat" class="mb-0 w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2"
                                :class="{
                                  'is-invalid':
                                    v$.form.personal.maritalStatus.$error,
                                }">
                                <input type="radio" v-model="form.personal.maritalStatus" value="widowed"
                                  name="materialRadio" class="inputRadioCstm d-none" id="widowedMat" disabled />
                                <span class="radioCircleIcon">
                                  <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                  <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                </span>
                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                  Widowed
                                </h4>
                              </label>
                              <div v-for="(personal, index) in v$.form.personal
                                  .maritalStatus.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal.$message">{{
                                  personal.$message
                                  }}</span>
                              </div>
                            </div>
                            <div class="col-auto mb-2">
                              <label for="separatedMat" class="mb-0 w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2"
                                :class="{
                                  'is-invalid':
                                    v$.form.personal.maritalStatus.$error,
                                }">
                                <input type="radio" v-model="form.personal.maritalStatus" value="separated"
                                  name="materialRadio" class="inputRadioCstm d-none" id="separatedMat" disabled />
                                <span class="radioCircleIcon">
                                  <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                  <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                </span>
                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                  Separated
                                </h4>
                              </label>
                              <div v-for="(personal, index) in v$.form.personal
                                  .maritalStatus.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal.$message">{{
                                  personal.$message
                                  }}</span>
                              </div>
                            </div>

                            <div class="col-auto mb-2">
                              <label for="commonLawMat" class="mb-0 w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2"
                                :class="{
                                  'is-invalid':
                                    v$.form.personal.maritalStatus.$error,
                                }">
                                <input type="radio" v-model="form.personal.maritalStatus" value="common_low"
                                  name="materialRadio" class="inputRadioCstm d-none" id="commonLawMat" disabled />
                                <span class="radioCircleIcon">
                                  <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                  <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                </span>
                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                  Common Law
                                </h4>
                              </label>
                              <div v-for="(personal, index) in v$.form.personal
                                  .maritalStatus.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal.$message">{{
                                  personal.$message
                                  }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">National Identification No.</label>
                            <input type="number" v-model="form.personal.nationalIdNum" class="form-control" :class="{
                                'is-invalid':
                                  v$.form.personal.nationalIdNum.$error,
                              }" placeholder="National Identification No." disabled />
                            <div v-for="(personal, index) in v$.form.personal
                                .nationalIdNum.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Issue Date</label>
                            <input type="date" v-model="form.personal.nidIssueDate" class="form-control" :class="{
                                'is-invalid':
                                  v$.form.personal.nidIssueDate.$error,
                              }" placeholder="Issue Date" disabled />
                            <div v-for="(personal, index) in v$.form.personal
                                .nidIssueDate.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Expiry Date</label>
                            <input type="date" v-model="form.personal.nidExpiry" class="form-control" :class="{
                                'is-invalid': v$.form.personal.nidExpiry.$error,
                              }" placeholder="Expiry Date" disabled />
                            <div v-for="(personal, index) in v$.form.personal
                                .nidExpiry.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Country of Issuance</label>
                            <div class="single-select2-cstm">
                              <Select2 v-model="form.personal.nationalIssuance" :class="{
                                  'is-invalid':
                                    v$.form.personal.nationalIssuance.$error,
                                }" placeholder="Select" :options="nationalIssuanceOpt" disabled />
                              <div v-for="(personal, index) in v$.form.personal
                                  .nationalIssuance.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal.$message">{{
                                  personal.$message
                                  }}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-3 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Driver’s Permit No.</label>
                            <input type="number" v-model="form.personal.drivingIdNum" class="form-control" :class="{
                                'is-invalid':
                                  v$.form.personal.drivingIdNum.$error,
                              }" placeholder="Driver’s Permit No." disabled />
                            <div v-for="(personal, index) in v$.form.personal
                                .drivingIdNum.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Issue Date</label>
                            <input type="date" v-model="form.personal.didIssueDate" class="form-control" :class="{
                                'is-invalid':
                                  v$.form.personal.didIssueDate.$error,
                              }" placeholder="Issue Date" disabled />
                            <div v-for="(personal, index) in v$.form.personal
                                .didIssueDate.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Expiry Date</label>
                            <input type="date" v-model="form.personal.didExpiry" class="form-control" :class="{
                                'is-invalid': v$.form.personal.didExpiry.$error,
                              }" placeholder="Expiry Date" disabled />
                            <div v-for="(personal, index) in v$.form.personal
                                .didExpiry.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Country of Issuance</label>
                            <div class="single-select2-cstm">
                              <Select2 v-model="form.personal.driverIssuance" placeholder="Select" :class="{
                                  'is-invalid':
                                    v$.form.personal.driverIssuance.$error,
                                }" :options="nationalIssuanceOpt" disabled />
                              <div v-for="(personal, index) in v$.form.personal
                                  .driverIssuance.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal.$message">{{
                                  personal.$message
                                  }}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-3 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Passport No.</label>
                            <input type="text" v-model="form.personal.passportNum" class="form-control" :class="{
                                'is-invalid':
                                  v$.form.personal.passportNum.$error,
                              }" placeholder="Passport No." disabled />
                            <div v-for="(personal, index) in v$.form.personal
                                .passportNum.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Issue Date</label>
                            <input type="date" v-model="form.personal.passIssueDate" class="form-control" :class="{
                                'is-invalid':
                                  v$.form.personal.passIssueDate.$error,
                              }" placeholder="Issue Date" disabled />
                            <div v-for="(personal, index) in v$.form.personal
                                .passIssueDate.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Expiry Date</label>
                            <input type="date" v-model="form.personal.passExpiry" class="form-control" :class="{
                                'is-invalid':
                                  v$.form.personal.passExpiry.$error,
                              }" placeholder="Expiry Date" disabled />
                            <div v-for="(personal, index) in v$.form.personal
                                .passExpiry.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Country of Issuance</label>
                            <div class="single-select2-cstm">
                              <Select2 v-model="form.personal.passportIssuance" placeholder="Select" :class="{
                                  'is-invalid':
                                    v$.form.personal.passportIssuance.$error,
                                }" :options="nationalIssuanceOpt" disabled />
                              <div v-for="(personal, index) in v$.form.personal
                                  .passportIssuance.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal.$message">{{
                                  personal.$message
                                  }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <template v-if="radioSelected === 'minor'">
                          <div class="col-lg-6 mb-3">
                            <div class="d-block">
                              <label class="font-size-14">Birth Certificate PIN No.
                                <sup>(for minors)</sup>
                              </label>
                              <input type="text" v-model="form.personal.birthIdNum" class="form-control" :class="{
                                  'is-invalid':
                                    v$.form.personal.birthIdNum.$error,
                                }" placeholder="Birth Certificate PIN No." disabled />
                              <div v-for="(personal, index) in v$.form.personal
                                  .birthIdNum.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal.$message">{{
                                  personal.$message
                                  }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 mb-3">
                            <div class="d-block">
                              <label class="font-size-14">Country of Issuance</label>
                              <div class="single-select2-cstm">
                                <Select2 v-model="form.personal.birthIssuance" placeholder="Select" :class="{
                                    'is-invalid':
                                      v$.form.personal.birthIssuance.$error,
                                  }" :options="nationalIssuanceOpt" disabled />
                                <div v-for="(personal, index) in v$.form.personal
                                    .birthIssuance.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal.$message">{{
                                    personal.$message
                                    }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>

                        <div class="col-lg-4 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Home Phone No.</label>
                            <input type="text" v-model="form.personal.homePhoneNum" class="form-control" :class="{
                                'is-invalid':
                                  v$.form.personal.homePhoneNum.$error,
                              }" placeholder="Home Phone No." disabled />
                            <div v-for="(personal, index) in v$.form.personal
                                .homePhoneNum.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Mobile No.</label>
                            <input type="text " v-model="form.personal.mobileNum" class="form-control" :class="{
                                'is-invalid': v$.form.personal.mobileNum.$error,
                              }" placeholder="Mobile No." disabled />
                            <div v-for="(personal, index) in v$.form.personal
                                .mobileNum.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Email Address</label>
                            <input type="email" v-model="form.personal.email" class="form-control" :class="{
                                'is-invalid': v$.form.personal.email.$error,
                              }" placeholder="Email Address" disabled />
                            <div v-for="(personal, index) in v$.form.personal.email
                                .$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="uploadFileSection groupFormStyle">
                        <h2 class="formMainHeading">Upload Documents</h2>
                        <div class="row">
                          <div class="col-lg-4 mb-3">
                            <div class="form-group">
                              <label for="my-file">National Identification</label>
                              <div>
                                <a v-if="imageUrl" :href="imageUrl" target="_blank">
                                  <i class="mdi mdi-file-document-multiple font-size-24 text-theme"></i>
                                  <div>
                                    Click to Download
                                  </div>
                                </a>
                                <div v-else>
                                  <i class="mdi mdi-file-document-multiple font-size-24 text-theme"></i>
                                  <div>No Document found</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 mb-3">
                            <div class="form-group">
                              <label for="my-file1">Driver’s Permit</label>
                              <div>
                                <a v-if="imageUrl_two" :href="imageUrl_two" target="_blank">
                                  <i class="mdi mdi-file-document-multiple font-size-24 text-theme"></i>
                                  <div>
                                    Click to Download
                                  </div>
                                </a>
                                <div v-else>
                                  <i class="mdi mdi-file-document-multiple font-size-24 text-theme"></i>
                                  <div>No Document found</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 mb-3">
                            <div class="form-group">
                              <label for="my-file2">Passport</label>
                              <div>
                                <a v-if="imageUrl_three" :href="imageUrl_three" target="_blank">
                                  <i class="mdi mdi-file-document-multiple font-size-24 text-theme"></i>
                                  <div>
                                    Click to Download
                                  </div>
                                </a>
                                <div v-else>
                                  <i class="mdi mdi-file-document-multiple font-size-24 text-theme"></i>
                                  <div>No Document found</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div class="col-lg-4 mb-3">
                            <div class="form-group">
                              <label for="my-file3">Utility Bill</label>
                              <div>
                                <a v-if="imageUrl_four" :href="imageUrl_four" target="_blank">
                                  <i class="mdi mdi-file-document-multiple font-size-24 text-theme"></i>
                                  <div>
                                    Click to Download
                                  </div>
                                </a>
                                <div v-else>
                                  <i class="mdi mdi-file-document-multiple font-size-24 text-theme"></i>
                                  <div>No Document found</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <template v-if="radioSelected1 === 'minor'">
                            <div class="col-lg-4">
                              <div class="form-group">
                                <label for="my-file2">Birth Certificate
                                  <sup>(For Minors)</sup></label>
                                <div>
                                  <a v-if="imageUrl_five" :href="imageUrl_five" target="_blank">
                                    <i class="mdi mdi-file-document-multiple font-size-24 text-theme"></i>
                                    <div>
                                      Click to Download
                                    </div>
                                  </a>
                                  <div v-else>
                                    <i class="mdi mdi-file-document-multiple font-size-24 text-theme"></i>
                                    <div>No Document found</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                          <div class="col-lg-4 mb-3">
                            <div class="form-group">
                              <label for="my-file3">Other's (Pay Slip Or Job Letter)</label>
                              <div>
                                <a v-if="imageUrl_nine" :href="imageUrl_nine" target="_blank">
                                  <i class="mdi mdi-file-document-multiple font-size-24 text-theme"></i>
                                  <div>
                                    Click to Download
                                  </div>
                                </a>
                                <div v-else>
                                  <i class="mdi mdi-file-document-multiple font-size-24 text-theme"></i>
                                  <div>No Document found</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tab-content>
                  <tab-content title="">
                    <h2 class="titleStepForm">Employment Information</h2>
                    <div class="row">
                      <div class="col-12 mb-3">
                        <h4 class="font-size-14">Employment Status</h4>
                        <div class="row">
                          <div class="col-auto mb-2">
                            <label for="permanentRd" class="mb-0 w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2"
                              :class="{
                                'is-invalid':
                                  v$.form.personal.employStatus.$error,
                              }">
                              <input type="radio" v-model="form.personal.employStatus" value="permanent"
                                class="inputRadioCstm d-none" id="permanentRd" name="empStat" disabled /><span
                                class="radioCircleIcon"><i class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                  class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Permanent
                              </h4>
                            </label>
                            <div v-for="(personal, index) in v$.form.personal
                                .employStatus.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="temporaryRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal.employStatus.$error,
                              }">
                              <input type="radio" v-model="form.personal.employStatus" value="temporary"
                                class="inputRadioCstm d-none" id="temporaryRd" name="empStat" disabled /><span
                                class="radioCircleIcon"><i class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                  class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Temporary
                              </h4>
                            </label>
                            <div v-for="(personal, index) in v$.form.personal
                                .employStatus.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <!-- <div class="col-auto">
                              <label for="casualRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{'is-invalid':v$.form.personal.employStatus.$error,}">
                                <input type="radio" v-model="form.personal.employStatus" value="casual"
                                  class="inputRadioCstm d-none" id="casualRd" name="empStat" disabled/><span
                                  class="radioCircleIcon"><i class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                  Casual
                                </h4>
                              </label>
                              <div v-for="(personal, index) in v$.form.personal
                                .employStatus.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal.$message">{{
                                  personal.$message
                                }}</span>
                              </div>
                            </div> -->
                          <div class="col-auto">
                            <label for="contractRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal.employStatus.$error,
                              }">
                              <input type="radio" v-model="form.personal.employStatus" value="contract"
                                class="inputRadioCstm d-none" id="contractRd" name="empStat" disabled /><span
                                class="radioCircleIcon"><i class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                  class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Contract
                              </h4>
                            </label>
                            <div v-for="(personal, index) in v$.form.personal
                                .employStatus.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="slfEmplyRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal.employStatus.$error,
                              }">
                              <input type="radio" v-model="form.personal.employStatus" value="self_employed"
                                class="inputRadioCstm d-none" id="slfEmplyRd" name="empStat" disabled /><span
                                class="radioCircleIcon"><i class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                  class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Self-Employed
                              </h4>
                            </label>
                            <div v-for="(personal, index) in v$.form.personal
                                .employStatus.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="unemployedRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal.employStatus.$error,
                              }">
                              <input type="radio" v-model="form.personal.employStatus" value="unemployed"
                                class="inputRadioCstm d-none" id="unemployedRd" name="empStat" disabled /><span
                                class="radioCircleIcon"><i class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                  class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Unemployed
                              </h4>
                            </label>
                            <div v-for="(personal, index) in v$.form.personal
                                .employStatus.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="retiredRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal.employStatus.$error,
                              }">
                              <input type="radio" v-model="form.personal.employStatus" value="retired"
                                class="inputRadioCstm d-none" id="retiredRd" name="empStat" disabled /><span
                                class="radioCircleIcon"><i class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                  class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Retired
                              </h4>
                            </label>
                            <div v-for="(personal, index) in v$.form.personal
                                .employStatus.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 mb-3">
                        <h4 class="font-size-14">Pay Frequency</h4>
                        <div class="row">
                          <div class="col-auto">
                            <label for="weekRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal.frequencyStatus.$error,
                              }">
                              <input type="radio" v-model="form.personal.frequencyStatus" value="weekly"
                                class="inputRadioCstm d-none" id="weekRd" name="pay_freq" disabled /><span
                                class="radioCircleIcon"><i class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                  class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Weekly
                              </h4>
                            </label>
                            <div v-for="(personal, index) in v$.form.personal
                                .frequencyStatus.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="fortnightRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal.frequencyStatus.$error,
                              }">
                              <input type="radio" v-model="form.personal.frequencyStatus" value="fortnightly"
                                class="inputRadioCstm d-none" id="fortnightRd" name="pay_freq" disabled /><span
                                class="radioCircleIcon"><i class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                  class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Fortnightly
                              </h4>
                            </label>
                            <div v-for="(personal, index) in v$.form.personal
                                .frequencyStatus.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="monthRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal.frequencyStatus.$error,
                              }">
                              <input type="radio" v-model="form.personal.frequencyStatus" value="monthly"
                                class="inputRadioCstm d-none" id="monthRd" name="pay_freq" disabled /><span
                                class="radioCircleIcon"><i class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                  class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Monthly
                              </h4>
                            </label>
                            <div v-for="(personal, index) in v$.form.personal
                                .frequencyStatus.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 mb-3">
                        <div class="d-block">
                          <label class="font-size-14">How Long Employed </label>
                          <input type="text" v-model="form.personal.user_howlong" class="form-control"
                            placeholder="How Long Employed" :class="{
                              'is-invalid':
                                v$.form.personal.user_howlong.$error,
                            }" disabled />
                          <div v-for="(personal, index) in v$.form.personal
                              .user_howlong.$errors" :key="index" class="invalid-feedback">
                            <span v-if="personal.$message">{{
                              personal.$message
                              }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-3">
                        <h4 class="font-size-14">Average Monthly Income</h4>
                        <input type="text" v-model="form.personal.averageIncm" class="form-control"
                          placeholder="How Long Employed" disabled />
                        <!-- <div class="row">
                            <div class="col-auto mb-2" v-for="(option, index) in optionsList" :key="index" >
                              <label :for="'priceRd-' + index" class="mb-0 w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{'is-invalid':v$.form.personal.averageIncm.$error}">
                                <input type="radio" class="inputRadioCstm d-none" :id="'priceRd-' + index"
                                  :value="option.id" name="priceGroup" v-model="form.personal.averageIncm" disabled/><span
                                  class="radioCircleIcon"><i class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                  {{ option.slab }}
                                </h4>
                              </label>
                              <div v-for="(personal, index) in v$.form.personal
                                .averageIncm.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal.$message">{{
                                  personal.$message
                                }}</span>
                              </div>
                            </div>
                          </div> -->
                      </div>
                      <div class="col-lg-6 mb-3">
                        <div class="d-block">
                          <label class="font-size-14">Occupation</label>
                          <input type="text" v-model="form.personal.user_occupation" class="form-control"
                            placeholder="Occupation" :class="{
                              'is-invalid':
                                v$.form.personal.user_occupation.$error,
                            }" disabled />
                          <div v-for="(personal, index) in v$.form.personal
                              .user_occupation.$errors" :key="index" class="invalid-feedback">
                            <span v-if="personal.$message">{{
                              personal.$message
                              }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 mb-3">
                        <div class="d-block">
                          <label class="font-size-14">Employer</label>
                          <input type="text" v-model="form.personal.employer" class="form-control"
                            placeholder="Employer" :class="{
                              'is-invalid': v$.form.personal.employer.$error,
                            }" disabled />
                          <div v-for="(personal, index) in v$.form.personal
                              .employer.$errors" :key="index" class="invalid-feedback">
                            <span v-if="personal.$message">{{
                              personal.$message
                              }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-3">
                        <div class="d-block">
                          <label class="font-size-14">Employer Address</label>
                          <textarea type="textarea" v-model="form.personal.user_employer"
                            class="form-control resize-none" rows="3" :class="{
                              'is-invalid':
                                v$.form.personal.user_employer.$error,
                            }" disabled placeholder="Address"></textarea>
                          <div v-for="(personal, index) in v$.form.personal
                              .user_employer.$errors" :key="index" class="invalid-feedback">
                            <span v-if="personal.$message">{{
                              personal.$message
                              }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 mb-3">
                        <div class="d-block">
                          <label class="font-size-14">Work Phone No</label>
                          <div class="row">
                            <div class="col-4 pe-0">
                              <div class="single-select2-cstm" style="min-width: 96px">
                                <Select2 v-model="form.personal.user_w_phone_nocode" :options="countrynameIssuanceOpt"
                                  disabled>
                                </Select2>
                              </div>
                            </div>
                            <div class="col-8 ps-0">
                              <input type="text" v-model="form.personal.user_w_phone_no" class="form-control"
                                placeholder="Work Phone No" :class="{
                                  'is-invalid':
                                    v$.form.personal.user_w_phone_no.$error,
                                }" disabled />
                            </div>
                          </div>
                          <div v-for="(personal, index) in v$.form.personal
                              .user_w_phone_no.$errors" :key="index" class="invalid-feedback">
                            <span v-if="personal.$message">{{
                              personal.$message
                              }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 mb-3">
                        <div class="d-block">
                          <label class="font-size-14">Ext. <sup>(if any)</sup></label>
                          <input type="number" v-model="form.personal.user_phone_no" class="form-control"
                            placeholder="Ext. (if any)" :class="{
                              'is-invalid':
                                v$.form.personal.user_phone_no.$error,
                            }" disabled />
                          <div v-for="(personal, index) in v$.form.personal
                              .user_phone_no.$errors" :key="index" class="invalid-feedback">
                            <span v-if="personal.$message">{{
                              personal.$message
                              }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-3">
                        <div class="d-block">
                          <label class="font-size-14">School
                            <sup>(if applicant is a student)</sup></label>
                          <textarea type="textarea" v-model="form.personal.user_isstudent"
                            class="form-control resize-none" rows="3" :class="{
                              'is-invalid':
                                v$.form.personal.user_isstudent.$error,
                            }" disabled></textarea>
                          <div v-for="(personal, index) in v$.form.personal
                              .user_isstudent.$errors" :key="index" class="invalid-feedback">
                            <span v-if="personal.$message">{{
                              personal.$message
                              }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tab-content>
                  <tab-content title="">
                    <h2 class="titleStepForm">Account Activity</h2>
                    <div class="row">
                      <div class="col-lg-6">
                        <label class="font-size-14">How will account be funded</label>
                        <div class="single-select2-cstm">
                          <Select2 v-model="form.personal.accFunded" :class="{
                              'is-invalid': v$.form.personal.accFunded.$error,
                            }" placeholder="Select" :options="fundedoption" disabled />
                          <div v-for="(personal, index) in v$.form.personal
                              .accFunded.$errors" :key="index" class="invalid-feedback">
                            <span v-if="personal.$message">{{
                              personal.$message
                              }}</span>
                          </div>
                        </div>
                      </div>
                      <br /><br /><br /><br /><br />
                      <div class="col-lg-6 mb-3">
                        <h4 class="font-size-14">
                          Are you a member of any other Credit Union / Financial
                          Institution?
                        </h4>
                        <p class="subTitleForm">
                          (Financial Institution e.g. Bank, UTC, Securities
                          Company)
                        </p>
                        <div class="row gx-2">
                          <div class="col-auto">
                            <label for="yesAcc" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0" :class="{
                                'is-invalid':
                                  v$.form.personal.accountActi.$error,
                              }">
                              <input type="radio" v-model="form.personal.accountActi" value="True"
                                name="accountActivity" class="inputRadioCstm d-none" id="yesAcc" disabled />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Yes
                              </h4>
                            </label>
                            <div v-for="(personal, index) in v$.form.personal
                                .accountActi.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="noAcc" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0" :class="{
                                'is-invalid':
                                  v$.form.personal.accountActi.$error,
                              }">
                              <input type="radio" v-model="form.personal.accountActi" value="False"
                                name="accountActivity" class="inputRadioCstm d-none" id="noAcc" disabled />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                No
                              </h4>
                            </label>
                            <div v-for="(personal, index) in v$.form.personal
                                .accountActi.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="textareaShow" v-if="form.personal.accountActi === 'True'" :class="{
                            'is-invalid': v$.form.personal.accountActi.$error,
                          }">
                          <textarea type="textarea" v-model="form.personal.accountActiDesc"
                            class="form-control resize-none" rows="4" disabled></textarea>
                        </div>
                        <div v-for="(personal, index) in v$.form.personal
                            .accountActi.$errors" :key="index" class="invalid-feedback">
                          <span v-if="personal.$message">{{
                            personal.$message
                            }}</span>
                        </div>
                      </div>
                      <!-- <div class="col-lg-6">
                        <label class="font-size-14">Include source of income
                        </label>
                        <div class="single-select2-cstm">
                          <Select2 v-model="form.personal.socuceofincome" :options="Incomeoption" disabled />
                        </div>
                      </div> -->
                      <div class="col-lg-6">
                        <label class="font-size-14">Include the area for details to be captured.</label>
                        <div class="single-select2-cstm">
                          <input type="text" v-model="form.personal.areaofdetails" disabled class="form-control" />
                        </div>
                      </div>
                      <br /><br /><br /><br />
                      <div class="col-12 mb-3">
                        <h3 class="mb-2 font-size-18">
                          Grouping for 'anticipated level of activity'
                        </h3>
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label class="font-size-14">Number of transactions</label>
                              <input type="text" v-model="form.personal.numTrans" class="form-control"
                                placeholder="Number of transactions" :class="{
                                  'is-invalid':
                                    v$.form.personal.numTrans.$error,
                                }" disabled />
                              <div v-for="(personal, index) in v$.form.personal
                                  .numTrans.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal.$message">{{
                                  personal.$message
                                  }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label class="font-size-14">Volume of Transactions (TT$)</label>
                              <input type="text" v-model="form.personal.volTrans" class="form-control"
                                placeholder="Volume of Transactions (TT$)" :class="{
                                  'is-invalid':
                                    v$.form.personal.volTrans.$error,
                                }" disabled />
                              <div v-for="(personal, index) in v$.form.personal
                                  .volTrans.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal.$message">{{
                                  personal.$message
                                  }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tab-content>
                  <tab-content title="" v-if="radioSelected1 === 'minor'">
                    <h2 class="titleStepForm">
                      For Minors Only <sup>(parent/guardian information)</sup>
                    </h2>
                    <div class="row">
                      <div class="col-lg-6 mb-3">
                        <div class="form-group">
                          <label class="font-size-14">Parent/ Legal Guardian: Name
                          </label>
                          <input type="text" v-model="form.personal.parentName" class="form-control"
                            placeholder="Parent/ Legal Guardian: Name " disabled />
                        </div>
                      </div>
                      <div class="col-lg-6 mb-3">
                        <div class="form-group">
                          <label class="font-size-14">Identification No. (ID/ DP/ PP)
                          </label>
                          <input type="text" v-model="form.personal.parentId" class="form-control"
                            placeholder="Identification No. (ID/ DP/ PP) " disabled />
                        </div>
                      </div>
                      <div class="col-lg-6 mb-3">
                        <div class="form-group">
                          <label class="font-size-14">Trustee:
                            <sup>(person authorised to act on behalf of
                              minor)</sup>
                          </label>
                          <input type="text" v-model="form.personal.trusteeName" class="form-control"
                            placeholder="Parent/ Legal Guardian: Name " disabled />
                        </div>
                      </div>
                      <div class="col-lg-6 mb-3">
                        <div class="form-group">
                          <label class="font-size-14">Identification No. (ID/ DP/ PP)
                          </label>
                          <input type="text" v-model="form.personal.trusteeId" class="form-control"
                            placeholder="Identification No. (ID/ DP/ PP) " disabled />
                        </div>
                      </div>
                    </div>
                    <div class="groupFormStyle mt-4">
                      <div class="">
                        <h3 class="font-size-18">Statement of Declaration</h3>
                        <p>FOR PERSON WHO IS NOT PARENT/ GUARDIAN/ TRUSTEE</p>
                      </div>
                      <div class="">
                        <label for="declareId" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                          <input type="checkbox" v-model="form.personal.declaration" name="declareNme"
                            class="inputRadioCstm d-none" id="declareId" disabled />
                          <span class="radioCircleIcon">
                            <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                            <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                          </span>
                          <div class="d-flex">
                            I ,
                            <input type="text" v-model="form.personal.advisor" class="form-control mx-1 p-0 w-50" style="
                                background-color: #fffbf8;
                                border: none;
                                border-bottom: 1px solid black;
                                outline: none;
                              " disabled />
                            declare as follows:
                          </div>
                        </label>
                        <ol class="listOlStyle">
                          <li>
                            That I of my own volition opened an account in the
                            name of
                            <strong> {{ form.personal.appliname }} </strong> who
                            is a minor
                          </li>
                          <li>
                            That I am neither parent nor legal guardian of this
                            minor
                          </li>
                          <li>
                            That I am aware that I will not have access to any
                            funds of this minor’s account unless authorised by
                            said minor’s parent or legal guardian.
                          </li>
                        </ol>
                      </div>
                    </div>
                  </tab-content>

                  <tab-content title="">
                    <h2 class="titleStepForm mb-2">
                      Politically Exposed Persons <sup>(PEPs)</sup>
                    </h2>
                    <p class="subTitleForm text-center">
                      Individuals who are or have been entrusted with prominent
                      functions by an international/ local organization such as:
                    </p>
                    <div class="formContentPoli mb-3">
                      <h3 class="font-size-22 mb-4 mt-5 text-center">
                        Please tick the one(s) that apply
                      </h3>
                      <p>
                        A <strong>“politically exposed person”</strong> (PEP)
                        means a person who is or was entrusted with a prominent
                        function by an international/ local organization or
                        important political functions such as:
                      </p>
                      <h5>International Organization:</h5>
                      <p>
                        Members of senior management such as directors and
                        members of the board or equivalent function. United
                        Nations and affiliated international organizations,
                        Organization of America States, Inter-American
                        Development Bank, International Labour Organization, and
                        Caribbean Financial Action Task Force.
                      </p>
                      <h5>Government:</h5>
                      <p>
                        Head of State, senior politician, senior government
                        official, judicial or military officials, and senior
                        executives of State owned corporations and important
                        political party officials. Senior government officials-
                        e.g. Permanent Secretary, Accounting Officer, Chief
                        Technical Officer, High Commissioner Senior executives
                        of state corporations e.g. Board members of all
                        Statutory Bodies. Senior political party officials e.g.
                        Chairman, Deputy Chairman, Secretary and Treasurer,
                        Judicial Official e.g. Judges, magistrates, Military
                        Officials e.g. a Lieutenant Colonel or higher rank.
                      </p>
                      <p>
                        An immediate family member or any individual publicly
                        known or actually known to the relevant financial
                        institution to be associated with a PEP.
                      </p>
                      <p>Are you or have you ever been:</p>
                    </div>
                    <div class="groupFormStyle">
                      <div class="mb-3">
                        <h3 class="font-size-15">
                          (a) Entrusted with a prominent function referred to
                          above
                        </h3>
                        <div class="row">
                          <div class="col-auto">
                            <label for="yesEntrust" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal.entrustedRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal.entrustedRadio" value="true"
                                class="inputRadioCstm d-none" id="yesEntrust" disabled />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Yes
                              </h4>
                            </label>
                            <div v-for="(personal, index) in v$.form.personal
                                .entrustedRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="noEntrust" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal.entrustedRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal.entrustedRadio" value="false"
                                class="inputRadioCstm d-none" id="noEntrust" disabled />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                No
                              </h4>
                            </label>
                            <div v-for="(personal, index) in v$.form.personal
                                .entrustedRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3">
                        <h3 class="font-size-15">
                          (b) an immediate family member of a person referred to
                          above (a) such as the spouse, parent, siblings,
                          children and children of the spouse of that person;
                        </h3>
                        <div class="row">
                          <div class="col-auto">
                            <label for="yesMember" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal.memberOfRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal.memberOfRadio" value="true"
                                class="inputRadioCstm d-none" id="yesMember" disabled />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Yes
                              </h4>
                            </label>
                            <div v-for="(personal, index) in v$.form.personal
                                .memberOfRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="noMember" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal.memberOfRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal.memberOfRadio" value="false"
                                class="inputRadioCstm d-none" id="noMember" disabled />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                No
                              </h4>
                            </label>
                            <div v-for="(personal, index) in v$.form.personal
                                .memberOfRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3">
                        <h3 class="font-size-15">
                          (C) a close personal or professional associate of the
                          persons referred to above (a) or (b)
                        </h3>
                        <div class="row">
                          <div class="col-auto">
                            <label for="yesReferred" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal.referredRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal.referredRadio" value="true"
                                class="inputRadioCstm d-none" id="yesReferred" disabled />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Yes
                              </h4>
                            </label>
                            <div v-for="(personal, index) in v$.form.personal
                                .referredRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="noReferred" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal.referredRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal.referredRadio" value="false"
                                class="inputRadioCstm d-none" id="noReferred" disabled />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                No
                              </h4>
                            </label>
                            <div v-for="(personal, index) in v$.form.personal
                                .referredRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="border-top pt-3">
                        <h4 class="text-center font-size-14 mb-0">
                          If you have answered “yes” to any of the questions
                          above, Enhanced Due Diligence Form to be completed
                        </h4>
                      </div>
                    </div>
                  </tab-content>

                  <tab-content title="">
                    <h2 class="titleStepForm">
                      Foreign Account Tax Compliance ACT <sup>(FATCA)</sup>
                    </h2>
                    <div class="">
                      <div class="mb-3 border-bottom pb-3">
                        <h3 class="font-size-16">
                          Are you a citizen of any country other than Trinidad
                          and Tobago?
                        </h3>
                        <p class="mb-0">
                          <strong class="font-size-13 font-weight-500 text-warning">REQUIREMENTS:</strong>
                          If yes, copies of relevant passport(s) to be provided.
                        </p>
                        <div class="row">
                          <div class="col-auto">
                            <label for="yesTabago" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal.tobagoRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal.tobagoRadio" class="inputRadioCstm d-none"
                                id="yesTabago" value="true" disabled />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Yes
                              </h4>
                            </label>
                            <div v-for="(personal, index) in v$.form.personal
                                .tobagoRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="noTabago" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal.tobagoRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal.tobagoRadio" class="inputRadioCstm d-none"
                                id="noTabago" disabled value="false" />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                No
                              </h4>
                            </label>
                            <div v-for="(personal, index) in v$.form.personal
                                .tobagoRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3 border-bottom pb-3">
                        <h3 class="font-size-16">
                          Are you a US Citizen, Resident or Green Card Holder?
                        </h3>
                        <p class="mb-0">
                          <strong class="font-size-13 font-weight-500 text-warning">REQUIREMENTS:</strong>
                          W-9 or W-8BEN , Document showing Immigration Status.
                        </p>
                        <div class="row mt-2 align-items-center">
                          <div class="col-md-4">
                            <div class="d-block">
                              <label class="font-size-14">SSN No</label>
                              <input type="text" v-model="form.personal.ssn_no" class="form-control"
                                placeholder="SSN No" :class="{
                                  'is-invalid': v$.form.personal.ssn_no.$error,
                                }" disabled />
                              <div v-for="(personal, index) in v$.form.personal
                                  .ssn_no.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal.$message">{{
                                  personal.$message
                                  }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="yesCitizen" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal.citizenRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal.citizenRadio" value="true"
                                class="inputRadioCstm d-none" id="yesCitizen" disabled />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Yes
                              </h4>
                            </label>
                            <div v-for="(personal, index) in v$.form.personal
                                .citizenRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="noCitizen" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal.citizenRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal.citizenRadio" value="false"
                                class="inputRadioCstm d-none" id="noCitizen" disabled />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                No
                              </h4>
                            </label>
                            <div v-for="(personal, index) in v$.form.personal
                                .citizenRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3 border-bottom pb-3">
                        <h3 class="font-size-16">Do you have a US address?</h3>
                        <p class="mb-0">
                          <strong class="font-size-13 font-weight-500 text-warning">REQUIREMENTS:</strong>
                          W-9 or W-8BEN
                        </p>
                        <div class="row">
                          <div class="col-auto">
                            <label for="yesAddress" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal.addressRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal.addressRadio" value="true"
                                class="inputRadioCstm d-none" id="yesAddress" disabled />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Yes
                              </h4>
                            </label>
                            <div v-for="(personal, index) in v$.form.personal
                                .addressRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="noAddress" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal.addressRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal.addressRadio" value="false"
                                class="inputRadioCstm d-none" id="noAddress" disabled />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                No
                              </h4>
                            </label>
                            <div v-for="(personal, index) in v$.form.personal
                                .addressRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3 border-bottom pb-3">
                        <h3 class="font-size-16">
                          Are you giving instructions for the transfer of
                          dividends/ other income to a US Account?
                        </h3>
                        <p class="mb-0">
                          <strong class="font-size-13 font-weight-500 text-warning">REQUIREMENTS:</strong>
                          W-9 or W-8BEN
                        </p>
                        <div class="row">
                          <div class="col-auto">
                            <label for="yesIncomeUs" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal.incomeUsRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal.incomeUsRadio" value="true"
                                class="inputRadioCstm d-none" id="yesIncomeUs" disabled />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Yes
                              </h4>
                            </label>
                            <div v-for="(personal, index) in v$.form.personal
                                .incomeUsRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="noIncomeUs" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal.incomeUsRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal.incomeUsRadio" value="false"
                                class="inputRadioCstm d-none" id="noIncomeUs" disabled />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                No
                              </h4>
                            </label>
                            <div v-for="(personal, index) in v$.form.personal
                                .incomeUsRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="pt-3">
                        <h4 class="text-center font-size-14 mb-0">
                          If you have answered “yes” to any of the questions
                          above, FATCA Documentation to be completed
                        </h4>
                      </div>
                    </div>
                  </tab-content>

                  <tab-content title="" v-if="userType === 'verification officer'">
                    <h2 class="titleStepForm">Account/Fees</h2>
                    <div class="">
                      <table class="table table-bordered table-custom-card">
                        <thead>
                          <tr>
                            <th>A/C Number</th>
                            <th>A/C Type</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <input type="number" v-model="form.personal.account_one" class="form-control" :class="{
                                  'is-invalid':
                                    v$.form.personal.account_one.$error,
                                }" />
                              <div v-for="(personal, index) in v$.form.personal
                                  .account_one.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal.$message">{{ personal.$message }}
                                </span>
                              </div>
                            </td>
                            <td>Shares</td>
                            <td>
                              <div class="input-group">
                                <div class="input-group-text">$</div>
                                <input type="number" v-model="form.personal.shares_amount" class="form-control" :class="{
                                    'is-invalid':
                                      v$.form.personal.shares_amount.$error,
                                  }" />
                                <div v-for="(personal, index) in v$.form.personal
                                    .shares_amount.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal.$message">{{ personal.$message }}
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <input type="number" v-model="form.personal.account_two" class="form-control" :class="{
                                  'is-invalid':
                                    v$.form.personal.account_two.$error,
                                }" />
                              <div v-for="(personal, index) in v$.form.personal
                                  .account_two.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal.$message">{{ personal.$message }}
                                </span>
                              </div>
                            </td>
                            <td>Special Shares</td>
                            <td>
                              <div class="input-group">
                                <div class="input-group-text">$</div>
                                <input type="number" v-model="form.personal.special_shares_amount" class="form-control"
                                  :class="{
                                    'is-invalid':
                                      v$.form.personal.special_shares_amount
                                        .$error,
                                  }" />
                                <div v-for="(personal, index) in v$.form.personal
                                    .special_shares_amount.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal.$message">{{ personal.$message }}
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <input type="number" v-model="form.personal.account_three" class="form-control" :class="{
                                  'is-invalid':
                                    v$.form.personal.account_three.$error,
                                }" />
                              <div v-for="(personal, index) in v$.form.personal
                                  .account_three.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal.$message">{{ personal.$message }}
                                </span>
                              </div>
                            </td>
                            <td>Deposit A/C</td>
                            <td>
                              <div class="input-group">
                                <div class="input-group-text">$</div>
                                <input type="number" v-model="form.personal.deposit_ac_amount" class="form-control"
                                  :class="{
                                    'is-invalid':
                                      v$.form.personal.deposit_ac_amount.$error,
                                  }" />
                                <div v-for="(personal, index) in v$.form.personal
                                    .deposit_ac_amount.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal.$message">{{ personal.$message }}
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <!-- <input type="number" v-model="fessacct" class="form-control"> -->
                            </td>
                            <td>
                              <strong class="font-weight-500">Reg. Fee</strong>
                            </td>
                            <td>
                              <div class="input-group">
                                <div class="input-group-text">$</div>
                                <input type="number" v-model="form.personal.reg_fee_amount" class="form-control" :class="{
                                    'is-invalid':
                                      v$.form.personal.reg_fee_amount.$error,
                                  }" />
                                <div v-for="(personal, index) in v$.form.personal
                                    .reg_fee_amount.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal.$message">{{ personal.$message }}
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <!-- <input type="number" class="form-control"> -->
                            </td>
                            <td>
                              <strong class="font-weight-500">Total</strong>
                            </td>
                            <td>
                              <div class="input-group">
                                <div class="input-group-text">$</div>
                                <input type="number" v-model="form.personal.total_amount" class="form-control" :class="{
                                    'is-invalid':
                                      v$.form.personal.total_amount.$error,
                                  }" />
                                <div v-for="(personal, index) in v$.form.personal
                                    .total_amount.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal.$message">{{ personal.$message }}
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </tab-content>

                  <tab-content title="">
                    <h2 class="titleStepForm">
                      Appointment of Nominee <sup>(Beneficiary)</sup>
                    </h2>
                    <div class="">
                      <p>
                        In the event of my death I,
                        <strong>{{ form.personal.appliname }} </strong> hereby
                        nominate the following person(s) to receive any monies
                        accruing to me in the Society:
                      </p>
                      <div class="groupFeildsNom border-bottom pb-3 mb-3">
                        <div class="row">
                          <div class="col-lg-4 mb-3">
                            <label class="font-size-14"> Name</label>
                            <input type="text" v-model="form.personal.appointnominee_name1" class="form-control"
                              placeholder="Name" :class="{
                                'is-invalid':
                                  v$.form.personal.appointnominee_name1.$error,
                              }" disabled />
                            <div v-for="(personal, index) in v$.form.personal
                                .appointnominee_name1.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-lg-8 mb-3">
                            <label class="font-size-14"> Email Address</label>
                            <input type="email" v-model="form.personal.appointnominee_email1" maxlength="100"
                              class="form-control" placeholder="Email address" :class="{
                                'is-invalid':
                                  v$.form.personal.appointnominee_email1.$error,
                              }" disabled />

                            <div v-for="(personal, index) in v$.form.personal
                                .appointnominee_email1.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-lg-4 mb-3">
                            <label class="font-size-14"> Date of Birth</label>
                            <input type="date" v-model="form.personal.appointnominee_dob1" maxlength="10"
                              class="form-control" placeholder="Relation to Member" :class="{
                                'is-invalid':
                                  v$.form.personal.appointnominee_dob1.$error,
                              }" disabled />
                            <div v-for="(personal, index) in v$.form.personal
                                .appointnominee_dob1.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-lg-4 mb-3">
                            <label class="font-size-14">Phone Number</label>
                            <div class="row">
                              <div class="col-4 pe-0">
                                <div class="single-select2-cstm">
                                  <Select2 disabled v-model="
                                      form.personal.appointnominee_pno1code
                                    " :options="countrynameIssuanceOpt">
                                  </Select2>
                                </div>
                              </div>
                              <div class="col-8 ps-0">
                                <input type="number" v-model="form.personal.appointnominee_pno1" maxlength="10"
                                  class="form-control" placeholder="Phone Number" :class="{
                                    'is-invalid':
                                      v$.form.personal.appointnominee_pno1
                                        .$error,
                                  }" disabled />
                              </div>
                            </div>
                            <div v-for="(personal, index) in v$.form.personal
                                .appointnominee_pno1.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>

                          <div class="col-lg-4 mb-3">
                            <label class="font-size-14"> Rel. to Member</label>
                            <input type="tel" v-model="form.personal.appointnominee_rel1" maxlength="10"
                              class="form-control" placeholder="Relation to Member" :class="{
                                'is-invalid':
                                  v$.form.personal.appointnominee_rel1.$error,
                              }" disabled />
                            <div v-for="(personal, index) in v$.form.personal
                                .appointnominee_rel1.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-lg-4 mb-3" v-if="form.personal.appointnominee_rel1 === 'Other family member'">
                            <label class="font-size-14">
                              Specify The Relation</label>
                            <input type="textarea" v-model="form.personal.appointnominee_rel_details"
                              class="form-control" disabled />
                          </div>
                          <div class="col-lg-12 mb-3">
                            <label class="font-size-14"> Address</label>
                            <input type="tel" v-model="form.personal.appointnominee_address1" maxlength="10"
                              class="form-control" placeholder="Relation to Member" :class="{
                                'is-invalid':
                                  v$.form.personal.appointnominee_address1
                                    .$error,
                              }" disabled />
                            <div v-for="(personal, index) in v$.form.personal
                                .appointnominee_address1.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <!-- <div class="col-lg-4 mb-3">
                              <label class="font-size-14"> Geo. Code</label>
                              <input type="number" v-model="form.personal.appointnominee_gcode1" maxlength="10"
                                class="form-control" placeholder="Geo. Code" :class="{'is-invalid':v$.form.personal.appointnominee_gcode1.$error}" disabled/>
                                <div v-for="(personal, index) in v$.form.personal
                                  .appointnominee_gcode1.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal.$message">{{
                                    personal.$message
                                  }}</span>
                                </div>
                            </div> -->
                        </div>
                      </div>
                      <div class="groupFeildsNom border-bottom pb-3 mb-3">
                        <div class="row">
                          <div class="col-lg-4 mb-3">
                            <label class="font-size-14"> Name </label>
                            <input type="text" v-model="form.personal.appointnominee_name2" class="form-control"
                              placeholder="Name" :class="{
                                'is-invalid':
                                  v$.form.personal.appointnominee_name2.$error,
                              }" disabled />
                            <div v-for="(personal, index) in v$.form.personal
                                .appointnominee_name2.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-lg-8 mb-3">
                            <label class="font-size-14"> Email Address</label>
                            <input type="email" v-model="form.personal.appointnominee_email2" maxlength="100"
                              class="form-control" placeholder="Email address" :class="{
                                'is-invalid':
                                  v$.form.personal.appointnominee_email2.$error,
                              }" disabled />

                            <div v-for="(personal, index) in v$.form.personal
                                .appointnominee_email2.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-lg-4 mb-3">
                            <label class="font-size-14"> Date of Birth</label>
                            <input type="date" v-model="form.personal.appointnominee_dob2" maxlength="10"
                              class="form-control" placeholder="Relation to Member" :class="{
                                'is-invalid':
                                  v$.form.personal.appointnominee_dob2.$error,
                              }" disabled />

                            <div v-for="(personal, index) in v$.form.personal
                                .appointnominee_dob2.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-lg-4 mb-3">
                            <label class="font-size-14"> Phone Number</label>
                            <div class="row">
                              <div class="col-4 pe-0">
                                <div class="single-select2-cstm">
                                  <Select2 disabled v-model="
                                      form.personal.appointnominee_pno1code2
                                    " :options="countrynameIssuanceOpt">
                                  </Select2>
                                </div>
                              </div>
                              <div class="col-8 ps-0">
                                <input type="number" v-model="form.personal.appointnominee_pno2" maxlength="10"
                                  class="form-control" placeholder="Phone Number" :class="{
                                    'is-invalid':
                                      v$.form.personal.appointnominee_pno2
                                        .$error,
                                  }" disabled />
                              </div>
                            </div>

                            <div v-for="(personal, index) in v$.form.personal
                                .appointnominee_pno2.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-lg-4 mb-3">
                            <label class="font-size-14"> Rel. to Member</label>
                            <input type="tel" v-model="form.personal.appointnominee_rel2" maxlength="10"
                              class="form-control" placeholder="Relation to Member" :class="{
                                'is-invalid':
                                  v$.form.personal.appointnominee_rel2.$error,
                              }" disabled />
                            <div v-for="(personal, index) in v$.form.personal
                                .appointnominee_rel2.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-lg-4 mb-3" v-if="form.personal.appointnominee_rel2 === 'Other family member'">
                            <label class="font-size-14">
                              Specify The Relation</label>
                            <input type="textarea" v-model="form.personal.appointnominee_rel2_details"
                              class="form-control" disabled />
                          </div>

                          <div class="col-lg-12 mb-3">
                            <label class="font-size-14"> Address</label>
                            <input type="tel" v-model="form.personal.appointnominee_address2" maxlength="10"
                              class="form-control" placeholder="Relation to Member" :class="{
                                'is-invalid':
                                  v$.form.personal.appointnominee_address2
                                    .$error,
                              }" disabled />
                            <div v-for="(personal, index) in v$.form.personal
                                .appointnominee_address2.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal.$message">{{
                                personal.$message
                                }}</span>
                            </div>
                          </div>
                          <!-- <div class="col-lg-4 mb-3">
                              <label class="font-size-14"> Geo. Code</label>
                              <input type="number" v-model="form.personal.appointnominee_gcode2" maxlength="10"
                                class="form-control" placeholder="Geo. Code" :class="{'is-invalid':v$.form.personal.appointnominee_gcode2.$error}" disabled/>
                                <div v-for="(personal, index) in v$.form.personal
                                  .appointnominee_gcode2.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal.$message">{{
                                    personal.$message
                                  }}</span>
                                </div>
                            </div> -->
                        </div>
                      </div>
                      <div class="">
                        <p>
                          I reserve the right to change or terminate the
                          designated beneficiary/ies at any time. I further
                          agree that any designation, termination or change of
                          beneficiary shall be binding upon the Credit Union
                          only if filed with the Credit Union prior to my death.
                          In accordance with the Co-operative Societies Act Chap
                          81:03 , as outlined in
                          <strong>ECU’s Bye Laws #10,</strong> a duly named
                          nominee of a deceased member of the Society is
                          entitled to the sum not exceeding fifty thousand dollars ($50,000.00) of the unencumbered
                          money due to the death of the said member of the Society. All other
                          monies due to the deceased member shall fall into his
                          estate and be subject in all respects to the laws
                          relating to inheritance including the requirement to
                          pay estate duty.
                        </p>
                        <!-- <div class="minorDivShow" v-if="radioSelected1 === 'minor'">
                            <h6 class="font-size-15">
                              Complete this Section only if Nominee is a minor
                            </h6>
                            <label for="hereByRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0" :class="{'is-invalid':v$.form.personal.hereRadio1.$error}">
                              <input type="checkbox" :checked="radioSelected1 === 'minor'? 'true' :'false'" v-model="form.personal.hereRadioType" class="inputRadioCstm d-none"
                              id="hereByRd" value="True" disabled/> -->
                        <div class="minorDivShow" v-if="radioSelected1 === 'minor'">
                          <h6 class="font-size-15">
                            Complete this Section only if Nominee is a minor
                          </h6>
                          <label for="hereByRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0" :class="{
                              'is-invalid': v$.form.personal.hereRadio1.$error,
                            }">
                            <input type="checkbox" :checked="
                                radioSelected1 === 'minor' ? 'true' : 'false'
                              " v-model="form.personal.hereRadio1" class="inputRadioCstm d-none" id="hereByRd"
                              value="True" disabled />
                            <span class="radioCircleIcon line-height-1">
                              <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                              <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                            </span>
                            <h4 class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">
                              I hereby nominate the following person/company to
                              act as; Trustee/Guardian on behalf of my nominee
                              should my nominee still be a minor (under age 18)
                              upon my death.
                            </h4>
                          </label>
                          <div v-for="(personal, index) in v$.form.personal
                              .hereRadio1.$errors" :key="index" class="invalid-feedback">
                            <span v-if="personal.$message">{{
                              personal.$message
                              }}</span>
                          </div>
                          <div class="row align-items-center">
                            <div class="col-lg-4 mb-3">
                              <div class="row gx-2">
                                <div class="col-auto">
                                  <label for="trusteeRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2"
                                    :class="{
                                      'is-invalid':
                                        v$.form.personal.trusteeRadioo.$error,
                                    }">
                                    <input type="radio" v-model="form.personal.trusteeRadioo" value="trustee"
                                      class="inputRadioCstm d-none" id="trusteeRd" />
                                    <span class="radioCircleIcon">
                                      <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                      <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                    </span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                      Trustee
                                    </h4>
                                  </label>
                                  <div v-for="(personal, index) in v$.form.personal
                                      .trusteeRadioo.$errors" :key="index" class="invalid-feedback">
                                    <span v-if="personal.$message">{{
                                      personal.$message
                                      }}</span>
                                  </div>
                                </div>
                                <div class="col-auto">
                                  <label for="guardianRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2"
                                    :class="{
                                      'is-invalid':
                                        v$.form.personal.trusteeRadioo.$error,
                                    }">
                                    <input type="radio" v-model="form.personal.trusteeRadioo" value="guardian"
                                      class="inputRadioCstm d-none" id="guardianRd" />
                                    <span class="radioCircleIcon">
                                      <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                      <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                    </span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                      Guardian
                                    </h4>
                                  </label>
                                  <div v-for="(personal, index) in v$.form.personal
                                      .trusteeRadioo.$errors" :key="index" class="invalid-feedback">
                                    <span v-if="personal.$message">{{
                                      personal.$message
                                      }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-8 mb-3">
                              <div class="d-block">
                                <label class="font-size-14">Relationship to Beneficiary</label>
                                <input type="text" v-model="form.personal.relationbenifit3" class="form-control"
                                  placeholder="Relationship to Beneficiary" :class="{
                                    'is-invalid':
                                      v$.form.personal.relationbenifit3.$error,
                                  }" disabled />
                                <div v-for="(personal, index) in v$.form.personal
                                    .relationbenifit3.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal.$message">{{
                                    personal.$message
                                    }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 mb-3" v-if="form.personal.relationbenifit3 === 'Other family member'">
                              <label class="font-size-14">
                                Specify The Relation</label>
                              <input type="textarea" v-model="form.personal.relationbenifit3_relation"
                                class="form-control" disabled />
                            </div>
                          </div>
                          <div class="groupFeildsNom border-top pt-3 mt-3">
                            <div class="row">
                              <div class="col-lg-4 mb-3">
                                <label class="font-size-14"> Name</label>
                                <input type="text" v-model="form.personal.appointnominee_name3" class="form-control"
                                  placeholder="Name" :class="{
                                    'is-invalid':
                                      v$.form.personal.appointnominee_name3
                                        .$error,
                                  }" disabled />
                                <div v-for="(personal, index) in v$.form.personal
                                    .appointnominee_name3.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal.$message">{{
                                    personal.$message
                                    }}</span>
                                </div>
                              </div>
                              <div class="col-lg-8 mb-3">
                                <label class="font-size-14">
                                  Email Address</label>
                                <input type="email" v-model="form.personal.appointnominee_email3" maxlength="100"
                                  class="form-control" placeholder="Email address" :class="{
                                    'is-invalid':
                                      v$.form.personal.appointnominee_email3
                                        .$error,
                                  }" disabled />

                                <div v-for="(personal, index) in v$.form.personal
                                    .appointnominee_email3.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal.$message">{{
                                    personal.$message
                                    }}</span>
                                </div>
                              </div>
                              <div class="col-lg-4 mb-3">
                                <label class="font-size-14">
                                  Date of Birth</label>
                                <input type="date" v-model="form.personal.appointnominee_dob3" maxlength="10"
                                  class="form-control" placeholder="Relation to Member" :class="{
                                    'is-invalid':
                                      v$.form.personal.appointnominee_dob3
                                        .$error,
                                  }" disabled />

                                <div v-for="(personal, index) in v$.form.personal
                                    .appointnominee_dob3.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal.$message">{{
                                    personal.$message
                                    }}</span>
                                </div>
                              </div>
                              <div class="col-lg-4 mb-3">
                                <label class="font-size-14">
                                  Phone Number</label>
                                <input type="number" v-model="form.personal.appointnominee_pno3" maxlength="10"
                                  class="form-control" placeholder="Phone Number" :class="{
                                    'is-invalid':
                                      v$.form.personal.appointnominee_pno3
                                        .$error,
                                  }" disabled />
                                <div v-for="(personal, index) in v$.form.personal
                                    .appointnominee_pno3.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal.$message">{{
                                    personal.$message
                                    }}</span>
                                </div>
                              </div>
                              <!-- <div class="col-lg-4 mb-3">
                                  <label class="font-size-14"> Geo. Code</label>
                                  <input type="number" v-model="form.personal.appointnominee_g_code3" maxlength="10"
                                    class="form-control" placeholder="Geo. Code" :class="{'is-invalid':v$.form.personal.appointnominee_g_code3.$error}"/>
                                    <div v-for="(personal, index) in v$.form.personal
                                      .appointnominee_g_code3.$errors" :key="index" class="invalid-feedback">
                                      <span v-if="personal.$message">{{
                                        personal.$message
                                      }}</span>
                                    </div>
                                </div> -->
                              <div class="col-lg-12 mb-3">
                                <label class="font-size-14"> Address</label>
                                <input type="tel" v-model="
                                    form.personal.appointnominee_address3
                                  " maxlength="10" class="form-control" placeholder="Address" :class="{
                                    'is-invalid':
                                      v$.form.personal.appointnominee_address3
                                        .$error,
                                  }" disabled />
                                <div v-for="(personal, index) in v$.form.personal
                                    .appointnominee_address3.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal.$message">{{
                                    personal.$message
                                    }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tab-content>

                  <tab-content title="">
                    <h2 class="titleStepForm">Member's Declaration</h2>
                    <div class="">
                      <p class="font-weight-500">
                        Eastern Credit Union Cooperative Society Limited is
                        required to comply with Anti- Money laundering and
                        Combating Terrorist Financing legislation (Proceeds of
                        Crime Act and Financial Obligations Regulations,
                        Financial Intelligence Unit) and the Foreign Account Tax
                        Compliance Act
                      </p>
                      <label for="hereByRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0" :class="{
                          'is-invalid': v$.form.personal.hereRadioType.$error,
                        }">
                        <input type="checkbox" v-model="form.personal.hereRadioType" value="true"
                          class="inputRadioCstm d-none" id="hereByRd" />
                        <span class="radioCircleIcon line-height-1">
                          <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                          <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                        </span>
                        <h4 class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">
                          I hereby apply for membership in
                          <strong class="font-weight-500">Eastern Credit Union Cooperative Society
                            Limited</strong>
                          and declare that the information given in this
                          Membership Application Form is true and correct.
                        </h4>
                      </label>
                      <div v-for="(personal, index) in v$.form.personal
                          .hereRadioType.$errors" :key="index" class="invalid-feedback">
                        <span v-if="personal.$message">{{
                          personal.$message
                          }}</span>
                      </div>
                      <p>
                        I agree to abide by the terms of the account(s)
                        agreement and with the Statutory Provision and bye-laws
                        governing the operations of
                        <strong class="font-weight-500">Eastern Credit Union Cooperative Society
                          Limited.</strong>
                        I am also aware that I am not a bona fide member of the
                        credit union until this application is approved by the
                        Board.
                      </p>
                    </div>
                    <div class="">
                      <div :class="
                          radioSelected1 === 'minor' ? 'border-bottom' : ''
                        " class="pb-3 mb-3">
                        <div class="row">
                          <div class="col-lg-6 mb-3">
                            <div class="d-block">
                              <label class="font-size-14">Applicant’s Name
                                <sup>(Block Letters)</sup></label>
                              <input type="text" v-model="form.personal.appliname" class="form-control"
                                placeholder="Applicant Name" disabled />
                              <!-- :class="{'is-invalid':v$.form.personal.appliname.$error}"
                                  <div v-for="(personal, index) in v$.form.personal
                                    .appliname.$errors" :key="index" class="invalid-feedback">
                                    <span v-if="personal.$message">{{
                                      personal.$message
                                    }}</span>
                                  </div> -->
                            </div>
                          </div>
                          <div class="col-lg-6 mb-3">
                            <div class="form-group">
                              <label for="my-file">Applicant’s Signature</label>
                              <div>
                                <a v-if="imageUrl_six" :href="imageUrl_six" target="_blank">
                                  <i class="mdi mdi-file-document-multiple font-size-24 text-theme"></i>
                                </a>
                                <div v-else>
                                  <i class="mdi mdi-file-document-multiple font-size-24 text-theme"></i>
                                  <div>No Document found</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <template v-if="radioSelected1 === 'minor'">
                      <div class="border-bottom pb-3 mb-3">
                        <div class="row">
                          <div class="col-lg-6 mb-3">
                            <div class="d-block">
                              <label class="font-size-14">Name of Parent/Guardian</label>
                              <input type="text" class="form-control" v-model="form.personal.nameofparent"
                                placeholder="Name of Parent/Guardian" disabled />
                            </div>
                          </div>

                          <div class="col-lg-6 mb-3">
                            <div class="form-group">
                              <label for="my-file">Parent/ Guardian’s Signature
                                <sup>(for minors)</sup></label>
                              <div>
                                <a v-if="imageUrl_seven" :href="imageUrl_seven" target="_blank">
                                  <i class="mdi mdi-file-document-multiple font-size-24 text-theme"></i>
                                </a>
                                <div v-else>
                                  <i class="mdi mdi-file-document-multiple font-size-24 text-theme"></i>
                                  <div>No Document found</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="pb-3 mb-3">
                        <div class="row">
                          <div class="col-lg-6 mb-3">
                            <div class="d-block">
                              <label class="font-size-14">Name of Trustee</label>
                              <input type="text" class="form-control" v-model="form.personal.nameoftrustee"
                                placeholder="Name of Trustee" disabled />
                            </div>
                          </div>
                          <div class="col-lg-6 mb-3">
                            <div class="form-group">
                              <label for="my-file">Trustee’s Signature
                                <sup>(for minors)</sup></label>
                              <div>
                                <a v-if="imageUrl_eight" :href="imageUrl_eight" target="_blank">
                                  <i class="mdi mdi-file-document-multiple font-size-24 text-theme"></i>
                                </a>
                                <div v-else>
                                  <i class="mdi mdi-file-document-multiple font-size-24 text-theme"></i>
                                  <div>No Document found</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <!-- <div class="border-bottom pb-3 mb-3"> -->
                    <!--<div class="row">
                             <div class="col-lg-4 mb-3">
                              <div class="d-block">
                                <label class="font-size-14">Name of ECU Representative
                                  <sup>(Block Letters)</sup></label>
                                <input type="text" v-model="form.personal.appliname8" class="form-control"
                                  placeholder="Name of ECU" :class="{'is-invalid':v$.form.personal.appliname8.$error}"/>
                                  <div v-for="(personal, index) in v$.form.personal
                                    .appliname8.$errors" :key="index" class="invalid-feedback">
                                    <span v-if="personal.$message">{{
                                      personal.$message
                                    }}</span>
                                  </div>
                              </div>
                            </div>
                            <div class="col-lg-4 mb-3">
                              <div class="form-group">
                                <label for="my-file">Signature of ECU Representative</label>
                                <input type="file"
                                  accept=".docx, .doc, application/pdf,application/vnd.ms-excel/, image/jpg, image/png"
                                  @change="previewImage($event, '7')" class="form-control" id="my-file" />
  
                                <template v-if="preview[7]">
                                  <div class="border bg-white p-2 mt-3">
                                    <h4 class="font-size-15 mb-3">
                                      Signature of ECU Representative Here:
                                    </h4>
                                    <img v-if="
                                      preview[7].type === 'pdf' ||
                                      preview[7].type === 'docx' ||
                                      preview[7].type === 'xlsx'
                                    " src="@/assets/images/ECU/icon-file.png" alt="icon-image"
                                      class="icon-file-upload" />
                                    <img v-else :src="preview[7].value" class="img-custom-preview" />
                                    <p class="mb-0">
                                      file name: {{ image[7].name }}
                                    </p>
                                    <p class="mb-0">
                                      size: {{ image[7].size / 1024 }}KB
                                    </p>
                                  </div>
                                </template>
                              </div>
                            </div> -->
                    <!-- <div class="col-lg-4 mb-3">
                              <div class="d-block">
                                <label class="font-size-14">Date</label>
                                <input type="date" v-model="form.personal.datename8" class="form-control" :class="{'is-invalid':v$.form.personal.datename8.$error}"/>
                                  <div v-for="(personal, index) in v$.form.personal
                                    .datename8.$errors" :key="index" class="invalid-feedback">
                                    <span v-if="personal.$message">{{
                                      personal.$message
                                    }}</span>
                                  </div>
                              </div>
                            </div> 
                          </div>-->
                    <!-- </div> -->
                    <!-- </div> -->
                  </tab-content>

                  <tab-content title="" v-if="showOfficial === true">
                    <h2 class="titleStepForm">For Official Use Only</h2>
                    <div class="row">
                      <div class="row">
                        <div class="col-lg-6 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Reviewer ‘s Name
                              <sup>(Block Letters)</sup></label>
                            <input type="text" v-model="reviewerName" class="form-control"
                              placeholder="Reviewer ‘s Name " disabled />
                          </div>
                        </div>
                        <div class="col-lg-6 mb-3">
                          <div class="form-group">
                            <label for="my-file" style="margin-right: 10px">Reviewer’s Signature</label>
                            <!-- <input type="file"
                                  accept=".docx, .doc, application/pdf,application/vnd.ms-excel/, image/jpg, image/png"
                                  @change="previewImage($event, '8')" class="form-control" id="my-file"  /> -->
                            <img :src="imageSrcSig" style="width: auto; height: 72px" />
                          </div>
                        </div>
                        <div class="col-lg-6 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Date</label>
                            <input type="date" v-model="reviewDate" class="form-control" placeholder="Date" disabled />
                          </div>
                        </div>
                        <div class="col-lg-6 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Date Membership Approved</label>
                            <input type="date" v-model="reviewApprovedDate" class="form-control"
                              placeholder="Date Membership Approved" disabled />
                          </div>
                        </div>
                        <div class="col-lg-12 mb-3">
                          <div class="form-group">
                            <label for="my-file" style="margin-right: 10px">Stamp</label>
                            <!-- <input type="file"
                                  accept="image/jpg, image/png"
                                  @change="previewImage($event, '9')" class="form-control" id="my-file"  /> -->
                            <img :src="imageSrcStamp" style="width: auto; height: 100px" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="groupFormStyle">
                      <div class="d-block mb-2">
                        <label for="checkOfficial" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                          <input type="checkbox" v-model="form.personal.checkListOffice1" class="inputRadioCstm d-none"
                            id="checkOfficial" />
                          <span class="radioCircleIcon line-height-1">
                            <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                            <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                          </span>
                          <p class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">
                            OFAC/ UN / FIUTT Domestic Lists Checked
                            <strong>(If positive match, refer to Branch Manager/
                              Compliance Dept.)</strong>
                          </p>
                        </label>
                      </div>
                      <div class="d-block mb-2">
                        <label for="checkValidForm" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                          <input type="checkbox" v-model="form.personal.checkListOffice2" class="inputRadioCstm d-none"
                            id="checkValidForm" />
                          <span class="radioCircleIcon line-height-1">
                            <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                            <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                          </span>
                          <p class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">
                            2 Valid Forms of Identification (i.e. National
                            Identification, Driver’s Permit, Passport)
                            <strong>If only one form of identification, approval from
                              Branch Manager</strong>
                          </p>
                        </label>
                      </div>
                      <div class="d-block mb-2">
                        <label for="checkIncomeRcnt" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                          <input type="checkbox" v-model="form.personal.checkListOffice3" class="inputRadioCstm d-none"
                            id="checkIncomeRcnt" />
                          <span class="radioCircleIcon line-height-1">
                            <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                            <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                          </span>
                          <p class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">
                            Verification of Income Recent Pay slip (One month)
                          </p>
                        </label>
                      </div>
                      <div class="d-block mb-2">
                        <label for="checkIncomeRcnt1" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                          <input type="checkbox" v-model="form.personal.checkListOffice4" class="inputRadioCstm d-none"
                            id="checkIncomeRcnt1" />
                          <span class="radioCircleIcon line-height-1">
                            <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                            <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                          </span>
                          <p class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">
                            Verification of employment/place of business e.g.
                            Job Letter (Three months)/Financials/Cash Flow
                            statement
                          </p>
                        </label>
                      </div>
                      <div class="d-block mb-2">
                        <label for="checkIncomeRcnt2" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                          <input type="checkbox" v-model="form.personal.checkListOffice5" class="inputRadioCstm d-none"
                            id="checkIncomeRcnt2" />
                          <span class="radioCircleIcon line-height-1">
                            <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                            <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                          </span>
                          <p class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">
                            Verification of Permanent Address- Recent Utility
                            Bill/Bank statement
                          </p>
                        </label>
                      </div>
                      <div class="d-block mb-2">
                        <label for="checkBirthCerti" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                          <input type="checkbox" v-model="form.personal.checkListOffice6" class="inputRadioCstm d-none"
                            id="checkBirthCerti" />
                          <span class="radioCircleIcon line-height-1">
                            <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                            <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                          </span>
                          <p class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">
                            Copy of Birth Certificate for Minors
                          </p>
                        </label>
                      </div>
                      <button @click="showModal = true" ref="anotherButton" type="button"
                        class="btn btn-theme btn-custom-wdth">
                        Feedback
                      </button>
                      <span v-if="submitting === true" style="margin-left: 10px; color: green">Submitting...</span>
                    </div>

                    <b-modal title="Document Feedback" v-model="showModal" hide-footer centered>
                      <div class="d-block mb-3">
                        <label class="font-size-14">Application Status</label>
                        <div class="single-select2-cstm">
                          <select v-model="selectStatusPro" class="form-select form-control selectCustomSize">
                            <option selected disabled value="select">
                              Please Select
                            </option>
                            <!-- <option value="inProgress">In progress</option> -->
                            <option value="submitReview">
                              Submitted and Under Review
                            </option>

                            <option value="Approved">Approved</option>
                            <option value="incomplete">Incomplete</option>
                            <option value="rejected">Rejected</option>
                          </select>
                        </div>
                      </div>
                      <template v-if="
                          selectStatusPro === 'incomplete' ||
                          selectStatusPro === 'rejected'
                        ">
                        <!-- mmbmn -->
                        <div class="groupFormStyle duplicate item mb-3" v-for="item in itemsss" :key="item">
                          <template v-if="selectStatusPro !== 'rejected'">
                            <div class="d-block mb-3">
                              <label class="font-size-14">Application Section</label>
                              <div class="single-select2-cstm">
                                <select v-model="incompleteAppStatus" class="form-select form-control selectCustomSize">
                                  <option selected disabled value="select">
                                    Please Select
                                  </option>
                                  <option value="section_a1">
                                    Personal Information
                                  </option>
                                  <option value="section_b2">
                                    Employment Information
                                  </option>
                                  <option value="section_c3">
                                    Account Activity
                                  </option>
                                  <option value="section_d4">
                                    Minors Only
                                  </option>
                                  <option value="section_e5">
                                    Politically Exposed Persons
                                  </option>
                                  <option value="section_f6">
                                    Foreign Account Tax Compliance ACT
                                  </option>
                                  <!-- <option value="section_g7">
                                    Account/Fees
                                  </option> -->
                                  <option value="section_h8">
                                    Appointment of Nominee
                                  </option>
                                  <option value="section_i9">
                                    Members Declaration
                                  </option>
                                </select>
                              </div>
                            </div>
                          </template>
                          <template v-if="
                              selectStatusPro == 'rejected' ||
                              selectStatusPro == 'incomplete'
                            ">
                            <div class="d-block mb-3">
                              <label class="font-size-14">Comment</label><textarea type="textarea" class="form-control"
                                rows="3" placeholder="Comment..." v-model="incompleteAppComment"></textarea>
                            </div>
                          </template>
                          <!-- <template v-if="selectStatusPro !== 'rejected'">
                                  <div class="d-block mb-3">
                                    <label class="font-size-14">Bug Attached </label>
                                    <input type="file" class="form-control" />
                                  </div>
                                </template> -->
                        </div>

                        `
                        <div class="d-flex justify-content-center">
                          <div class="item addMoreBug-btn" @click="duplicateItem">
                            <button class="btn-sm btn-theme-dark">
                              Add More Bug
                            </button>
                          </div>
                        </div>
                        `
                      </template>
                      <div class="d-block text-end">
                        <input type="submit" class="btn btn-theme btn-custom-wdth" value="Submit"
                          @click="submitFeedback" />
                      </div>
                    </b-modal>
                  </tab-content>
                </form-wizard>
              </template>
            </div>
          </div>
        </div>
      </div>
    </main>
  </Layout>
</template>

<style>
  .preloader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .preloader {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 4px solid #f3f3f3;
    border-top-color: #f48226;
    animation: spin 1s infinite linear;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
</style>
<script>
  import Layout from "../../layouts/main";
  import { FormWizard, TabContent } from "vue3-form-wizard";
  import "vue3-form-wizard/dist/style.css";
  import Select2 from "vue3-select2-component";
  import axios from "axios";
  import useVuelidate from "@vuelidate/core";
  import { required, helpers } from "@vuelidate/validators";
  import base_domain from "../../myvars";
  axios.defaults.baseURL = base_domain;

  export default {
    beforeRouteLeave(to, from, next) {
      this.$emit("saveFormData", this.form);
      next();
    },

    setup() {
      return { v$: useVuelidate() };
    },
    mounted() {
      const buttons = document.querySelectorAll(".wizard-btn");
      buttons.forEach((button) => {
        button.addEventListener("click", this.handleNext);
      });
      this.$refs.myWizard.activateAll();

      // this.handlefetchdata();
      //   this.fetchData();
      //   this.incSlab();
      this.chkStaff();
    },
    beforeUnmount() {
      const buttons = document.querySelectorAll(".wizard-btn");
      buttons.forEach((button) => {
        button.removeEventListener("click", this.handleNext);
      });
    },
    data() {
      return {
        check_type: localStorage.getItem("userType"),
        imageUrl: null,
        imageUrl_two: null,
        imageUrl_three: null,
        imageUrl_four: null,
        imageUrl_five: null,
        imageUrl_six: null,
        imageUrl_seven: null,
        imageUrl_eight: null,
        imageUrl_nine: null,
        fileIconUrl: "https://www.flaticon.com/free-icon/file_124837",
        submitting: false,
        incompleteAppStatus: "select",
        // incompleteAppStatus: [],
        incompleteAppComment: "",
        showModal: false,
        selectStatusPro: "submitReview",
        reviewerName: "",
        reviewDate: "",
        reviewApprovedDate: "",
        imageSrcStamp: "",
        imageSrcSig: "",
        optionsList: [],
        itemsss: [0],
        startIndex: 0,
        app_id: "",
        isLoading: false,
        showOfficial: false,
        radioSelected1: "",
        checkFeildInput1: "",
        checkFeildInput2: "",
        riskInput: "",
        app_URL: process.env.VUE_APP_URL,
        showValue: true,
        fatcaFeild: "",
        checkFeildInput: [],
        radioSelected: "",
        nationalIssuance: "",
        fundedoption: [
          "Salary",
          "Allowance from spouse or parent",
          "Business proceeds",
          "Pension",
          "Standing Order from Financial Institution",
          "Other",
        ],
        Incomeoption: [
          "Salaried",
          "Business",
          "Unemployed",
          "Retired",
          "Self-employed",
        ],

        // files:'',
        showRadio: true,
        preview: [],
        image: [],
        // preview_list: [],
        // image_list: [],
        driverIssuance: "",
        passportIssuance: "",
        birthIssuance: "",
        countrynameIssuanceOpt: [
          "+93-AFG",
          "+355-ALB",
          "+213-DZA",
          "+376-AND",
          "+244-AGO",
          "+1-ATG",
          "+54-ARG",
          "+374-ARM",
          "+297-AABW",
          "+61-AUS",
          "+43-AUT",
          "+994-AZE",
          "+1-BHS",
          "+973-BHR",
          "+880-BGD",
          "+1-BRB",
          "+375-BLR",
          "+32-BEL",
          "+501-BLZ",
          "+229-BEN",
          "975-BTN",
          "+591-BOL",
          "+387-BIH",
          "+267-BWA",
          "+55-BRA",
          "+673-BRN",
          "+359-BGR",
          "+226-BFA",
          "+257-BDI",
          "+855-KHM",
          "+237-CMR",
          "+1-CAN",
          "+238-CPV",
          "+236-CAF",
          "+235-TCD",
          "+56-CHL",
          "+86-CHN",
          "+57-COL",
          "+269-COM",
          "+242-COG",
          "+682-COK",
          "506-CRI",
          "+225-CIV",
          "+385-HRV",
          "+53-CUB",
          "+357-CYP",
          "+420-CZE",
          "+45-DNK",
          "+253-DJI",
          "+1-DMA",
          "+1-DOM",
          "+593-ECU",
          "20-EGY",
          "+503-SLV",
          "+240-GNQ",
          "+291-ERI",
          "+372-EST",
          "+251-ETH",
          "+500-FLK",
          "+298-FRO",
          "+679-FJI",
          "+358-FIN",
          "+33-FRA",
          "+689-PYF",
          "+241-GAB",
          "+220-GMB",
          "+995-GEO",
          "+49-DEU",
          "+233-GHA",
          "+350-GIB",
          "+30-GRC",
          "+299-GRL",
          "+1-GRD",
          "+502-GTM",
          "224-GIN",
          "+245-GNB",
          "+592-GUY",
          "+509-HTI",
          "+504-HND",
          "+852-HKG",
          "+36-HUN",
          "+354-ISL",
          "+91-IND",
          "+62-IDN",
          "+98-IRN",
          "964-IRQ",
          "+353-IRL",
          "+44-IMN",
          "+972-ISR",
          "+39-ITA",
          "+1-JAM",
          "+81-JPN",
          "+962-JOR",
          "+7-KAZ",
          "+254-KEN",
          "+686-KIR",
          "383-XKX",
          "+850-PRK",
          "+82-KOR",
          "+965-KWT",
          "+996-KGZ",
          "+856-LAO",
          "+371-LVA",
          "+961-LBN",
          "+266-LSO",
          "+231-LBR",
          "+218-LBY",
          "+423-LIE",
          "+370-LTU",
          "+352-LUX",
          "+853-MAC",
          "+389-MKD",
          "+261-MDG",
          "+265-MWI",
          "+60-MYS",
          "+960-MDV",
          "+223-MLI",
          "+356-MLT",
          "+692-MHL",
          "+222-MRT",
          "+230-MUS",
          "+262-REU",
          "+52-MEX",
          "+691-FSM",
          "+373-MDA",
          "+377-MCO",
          "+976-MNG",
          "+382-MNE",
          "+1-MSR",
          "+212-MAR",
          "+258-MOZ",
          "+95-MMR",
          "+264-NAM",
          "+674-NRU",
          "+977-NPL",
          "+31-NLD",
          "+599-NLD",
          "687-NCL",
          "+64-NZL",
          "+505-NIC",
          "+227-NER",
          "+234-NGA",
          "+683-NIU",
          "+672-NFK",
          "+47-NOR",
          "+968-OMN",
          "+92-PAK",
          "+680-PLW",
          "970-PSE",
          "+507-PAN",
          "+675-PNG",
          "+595-PRY",
          "+51-PER",
          "+63-PHL",
          "+48-POL",
          "+351-PRT",
          "+1-PRK",
          "+974-QAT",
          "+40-ROU",
          "7-RUS",
          "+250-RWA",
          "+590-BLM",
          "+290-SHN",
          "+1-KNA",
          "+1-LCA",
          "+590-MFA",
          "+508-SPM",
          "+1-VCT",
          "+685-WSM",
          "+378-SMR",
          "239-STP",
          "+966-SAU",
          "+221-SEN",
          "+381-SRB",
          "+248-SYC",
          "+232-SLE",
          "+65-SGP",
          "+599-SXM",
          "+421-SVK",
          "+386-SVN",
          "+677-SLB",
          "+252-SOM",
          "+27-ZAF",
          "+211-SSD",
          "+34-ESP",
          "+94-LKA",
          "+249-SDN",
          "+597-SUR",
          "+268-SWZ",
          "+46-SWE",
          "+41-CHE",
          "963-SYR",
          "+886-TWN",
          "+992-TJK",
          "+255-TZA",
          "+66-THA",
          "+670-TLS",
          "+228-TGO",
          "+690-TKL",
          "+676-TON",
          "+868-TTO",
          "+216-TUN",
          "90-TUR",
          "+993-TKM",
          "+688-TUV",
          "+256-UGA",
          "+380-UKR",
          "+971-ARE",
          "+44-GBR",
          "+1-USA",
          "+598-URY",
          "+998-UZB",
          "+678-VUT",
          "39-VA",
          "+58-VEN",
          "+84-VNM",
          "+681-WLF",
          "+212-ESH",
          "+967-YEM",
          "+260-ZMB",
          "+263-ZWE",
        ],
        sectionOptions: [
          { value: "section_a1", label: "Personal Information" },
          { value: "section_b2", label: "Employment Information" },
          { value: "section_c3", label: "Account Activity" },
          { value: "section_d4", label: "Minors Only" },
          { value: "section_e5", label: "Politically Exposed Persons" },
          { value: "section_f6", label: "Foreign Account Tax Compliance ACT" },
          // { value: "section_g7", label: "Account/Fees" },
          { value: "section_h8", label: "Appointment of Nominee" },
          { value: "section_i9", label: "Members Declaration" },
        ],
        nationalIssuanceOpt: [
          "Afghanistan",
          "Aland Islands",
          "Albania",
          "Algeria",
          "American Samoa",
          "Andorra",
          "Angola",
          "Anguilla",
          "Antarctica",
          "Antigua and Barbuda",
          "Argentina",
          "Armenia",
          "Aruba",
          "Australia",
          "Austria",
          "Azerbaijan",
          "Bahamas",
          "Bahrain",
          "Bangladesh",
          "Barbados",
          "Belarus",
          "Belgium",
          "Belize",
          "Benin",
          "Bermuda",
          "Bhutan",
          "Bolivia",
          "Bonaire",
          "Bosnia and Herzegovina",
          "Botswana",
          "Bouvet Island",
          "Brazil",
          "British Indian Ocean Territory",
          "British Virgin Islands",
          "Brunei",
          "Bulgaria",
          "Burkina Faso",
          "Burundi",
          "Cambodia",
          "Cameroon",
          "Canada",
          "Cape Verde",
          "Cayman Islands",
          "Central African Republic",
          "Chad",
          "Chile",
          "China",
          "Christmas Island",
          "Cocos (Keeling) Islands",
          "Colombia",
          "Comoros",
          "Congo (Brazzaville)",
          "Congo (Kinshasa)",
          " Cook Islands",
          "Costa Rica",
          "Croatia",
          "Curaçao",
          "Cyprus",
          "Czech Republic",
          "Denmark",
          "Djibouti",
          "Dominica",
          "Dominican Republic",
          "East Timor",
          "Ecuador",
          "Egypt",
          "El Salvador",
          "Equatorial Guinea",
          "Eritrea",
          "Estonia",
          "Ethiopia",
          "Falkland Islands",
          "Faroe Islands",
          "Fiji",
          "Finland",
          "France",
          "French Guiana",
          "French Polynesia",
          "French Southern Territories",
          "Gabon",
          "Gambia",
          "Georgia",
          "Germany",
          "Ghana",
          "Gibraltar",
          "Greece",
          "Greenland",
          "Grenada",
          "Guadeloupe",
          "Guam",
          "Guatemala",
          "Guernsey",
          "Guinea",
          "Guinea-Bissau",
          "uyana",
          "Haiti",
          "Heard Island and McDonald Islands",
          "Honduras",
          "Hong Kong",
          "Hungary",
          "Iceland",
          "India",
          "Indonesia",
          "Ireland",
          "Isle of Man",
          "Israel",
          "Italy",
          "Jamaica",
          "Japan",
          "Jersey",
          "Jordan",
          "Kazakhstan",
          "Kenya",
          "Kiribati",
          "Kuwait",
          "Kyrgyzstan",
          "Laos",
          "Latvia",
          "Lebanon",
          "Lesotho",
          "Liberia",
          "Libya",
          "Liechtenstein",
          "Lithuania",
          "Luxembourg",
          "Macau",
          "Macedonia",
          "Madagascar",
          "Malawi",
          "Malaysia",
          "Maldives",
          "Mali",
          "Malta",
          "Marshall Islands",
          "Martinique",
          "Mauritania",
          "Mauritius",
          "Mayotte",
          "Mexico",
          "Micronesia",
          "Moldova",
          "Monaco",
          "Mongolia",
          "Montenegro",
          "Montserrat",
          "Morocco",
          "Mozambique",
          "Myanmar",
          "Namibia",
          "Nauru",
          "Nepal",
          "Netherlands",
          "New Caledonia",
          "New Zealand",
          "Nicaragua",
          "Niger",
          "Nigeria",
          "Niue",
          "Norfolk Island",
          "Northern Mariana Islands",
          "Norway",
          "Oman",
          "Pakistan",
          "Palau",
          "Palestinian Territories",
          "Panama",
          "Papua New Guinea",
          "Paraguay",
          "Peru",
          "Philippines",
          "Pitcairn",
          "Poland",
          "Portugal",
          "Puerto Rico",
          "Qatar",
          "Republic of Côte d’Ivoire",
          "Republic of Kosovo",
          "Reunion",
          "Romania",
          "Russia",
          "Rwanda",
          "Saint Barthélemy",
          "Saint Helena",
          "Saint Kitts and Nevis",
          "Saint Lucia",
          "Saint Martin",
          "Saint Pierre and Miquelon",
          "Saint Vincent and the Grenadines",
          "Samoa",
          "San Marino",
          "Sao Tome and Principe",
          "Saudi Arabia",
          "Senegal",
          "Serbia",
          "Seychelles",
          "Sierra Leone",
          "Singapore",
          "Sint Maarten",
          "Slovakia",
          "Slovenia",
          "Solomon Islands",
          "Somalia",
          "South Africa",
          "South Georgia and the South Sandwich Islands",
          "South Korea",
          "Spain",
          "Sri Lanka",
          "Sudan",
          "Suriname",
          "Svalbard and Jan Mayen",
          "Swaziland",
          "Sweden",
          "Switzerland",
          "Taiwan",
          "Tajikistan",
          "Tanzania",
          "Thailand",
          "Togo",
          "Tokelau",
          "Tonga",
          "Trinidad and Tobago",
          "Tunisia",
          "Turkey",
          "Turkmenistan",
          "Turks and Caicos Islands",
          "Tuvalu",
          "U.S. Virgin Islands",
          "Uganda",
          "Ukraine",
          "United Arab Emirates",
          "United Kingdom",
          "United States",
          "United States Minor Outlying Islands",
          "Uruguay",
          "Uzbekistan",
          "Vanuatu",
          "Vatican",
          "Venezuela",
          "Vietnam",
          "Wallis and Futuna",
          "Western Sahara",
          "Yemen",
          "Zambia",
          "Zimbabwe",
        ],
        form: {
          personal: {
            branchName: "",
            // personNum: "",
            // memberNum: "",
            title: "",
            surname: "",
            firstName: "rajkumar",
            middleName: "",
            address: "",
            // geoCode: "",
            // mailingAddress: "",
            address_line: "",
            address_line_two: "",
            city_name: "",
            state_name: "",
            zip_code: "",
            country_name: "",
            dob: "",
            gender: "",
            numOfDependents: "",
            placeOfdob: "",
            nationality: "",
            maritalStatus: "",
            nationalIdNum: "",
            nidIssueDate: "",
            nidExpiry: "",
            nationalIssuance: "",
            naitonal_identi: "",
            drivingIdNum: "",
            didIssueDate: "",
            didExpiry: "",
            driverIssuance: "",
            passportNum: "",
            passIssueDate: "",
            passExpiry: "",
            passportIssuance: "",
            birthIdNum: "",
            birthIssuance: "",
            homePhoneNum: "",
            mobileNum: "",
            email: "",
            nidDoc: [],
            didDoc: [],
            passDoc: [],
            birthDoc: [],

            employStatus: "",
            frequencyStatus: "",
            averageIncm: "",
            user_occupation: "",
            employer: "",
            user_employer: "",
            user_w_phone_no: "",
            user_w_phone_nocode: "",
            user_phone_no: "",
            user_isstudent: "",
            user_howlong: "",
            accFunded: "",
            accountActi: "",
            accountActiDesc: "",
            numTrans: "",
            volTrans: "",
            parentName: "",
            parentId: "",
            trusteeName: "",
            advisor: "",
            trusteeId: "",
            declaration: "",
            referredRadio: "",
            entrustedRadio: "",
            memberOfRadio: "",
            tobagoRadio: "",
            ssn_no: "",
            citizenRadio: "",
            addressRadio: "",
            incomeUsRadio: "",
            //-----------------------------
            account_one: "",
            shares_amount: "",
            account_two: "",
            special_shares_amount: "",
            account_three: "",
            deposit_ac_amount: "",
            reg_fee_amount: "",
            total_amount: "",
            //-----------------------------
            appointnominee_name1: "",
            appointnominee_email1: "",
            appointnominee_pno1: "",
            appointnominee_pno1code: "",
            appointnominee_rel1: "",
            appointnominee_address1: "",
            appointnominee_dob1: "",

            appointnominee_name2: "",
            appointnominee_email2: "",
            appointnominee_dob2: "",
            appointnominee_pno2: "",
            appointnominee_pno1code2: "",
            appointnominee_rel2: "",
            appointnominee_address2: "",

            hereRadioType: true,
            trusteeRadioo: "",
            relationbenifit3: "",
            appointnominee_name3: "",
            appointnominee_pno3: "",
            socuceofincome: "",
            areaofdetails: "",
            appointnominee_email3: "",
            appointnominee_dob3: "",
            appointnominee_address3: "",
            // --------------------------------
            hereRadioType_one: "",
            appliname: "",
            checkListOffice1: "",
            checkListOffice2: "",
            checkListOffice3: "",
            checkListOffice4: "",
            checkListOffice5: "",
            checkListOffice6: "",
          },
          // story: {
          //   clientName: '',
          //   storyTitle: '',
          //   storyGenre: '',
          //   wordCount: '',
          //   notesFromClient: '',
          //   files: [],
          //   submissionPackageId: null,
          //   fictionPackageId: null,
          //   editingPackageId: null,
          // },
        },
      };
    },
    created() {
      var app = window.location.href;
      const segments = app.split("/");
      const app_id = segments[segments.length - 1];
      // const issueId = 1; // Replace 1 with the actual issue ID you want to retrieve the image for
      this.getIssueImageUrl(app_id);
    },
    validations: {
      form: {
        personal: {
          branchName: {
            required: helpers.withMessage("Branch Name is required...", required),
          },
          // personNum: {
          //   required: helpers.withMessage("Person Number is required...",required),
          // },
          // memberNum: {
          //   required: helpers.withMessage("Member Name is required...", required),
          // },
          title: {
            required: helpers.withMessage("Title is required...", required),
          },
          surname: {
            required: helpers.withMessage("Surname is required...", required),
          },
          firstName: {
            required: helpers.withMessage("First Name is required...", required),
          },
          middleName: {
            required: helpers.withMessage("Middle Name is required...", required),
          },
          address: {
            required: helpers.withMessage("Address is required...", required),
            // email: helpers.withMessage("Enter a valid Email", email),
          },
          // geoCode: {
          //   required: helpers.withMessage("GeoCode is required...", required),
          // },
          // mailingAddress: {required: helpers.withMessage("Mailing Address is required...", required),
          // },
          address_line: {
            required: helpers.withMessage("Address is required...", required),
          },
          address_line_two: {
            required: helpers.withMessage("Address is required...", required),
          },
          city_name: {
            required: helpers.withMessage("City Name is required...", required),
          },
          state_name: {
            required: helpers.withMessage("State Name is required...", required),
          },
          zip_code: {
            required: helpers.withMessage("Zip Code is required...", required),
          },
          country_name: {
            required: helpers.withMessage(
              "Country Name is required...",
              required
            ),
          },
          maritalStatus: {
            required: helpers.withMessage("Marital is required...", required),
          },
          dob: {
            required: helpers.withMessage(
              "Date of Birth is required...",
              required
            ),
          },
          gender: {
            required: helpers.withMessage("Gender is required...", required),
          },
          numOfDependents: {
            required: helpers.withMessage("Dependents is required...", required),
          },
          placeOfdob: {
            required: helpers.withMessage("Place is required...", required),
          },
          nationality: {
            required: helpers.withMessage("Nationality is required...", required),
          },
          nationalIdNum: {
            required: helpers.withMessage(
              "National Id Num is required...",
              required
            ),
          },
          nidIssueDate: {
            required: helpers.withMessage(
              "NID Issue Date is required...",
              required
            ),
          },
          nidExpiry: {
            required: helpers.withMessage(
              "NID Expiry Date is required...",
              required
            ),
          },
          nationalIssuance: {
            required: helpers.withMessage(
              "National Issuance is required...",
              required
            ),
          },
          drivingIdNum: {
            required: helpers.withMessage("Permit No is required...", required),
          },
          didIssueDate: {
            required: helpers.withMessage("Issue Date is required...", required),
          },
          didExpiry: {
            required: helpers.withMessage("Expiry Date is required...", required),
          },
          driverIssuance: {
            required: helpers.withMessage("Issuance is required...", required),
          },
          passportNum: {
            required: helpers.withMessage("Passport No is required...", required),
          },
          passIssueDate: {
            required: helpers.withMessage("Issue Date is required...", required),
          },
          passExpiry: {
            required: helpers.withMessage(
              "Passport Expiry Date is required...",
              required
            ),
          },
          passportIssuance: {
            required: helpers.withMessage("Issuance is required...", required),
          },
          birthIdNum: {
            required: helpers.withMessage("Birthday is required...", required),
          },
          birthIssuance: {
            required: helpers.withMessage("Issurance is required...", required),
          },
          homePhoneNum: {
            required: helpers.withMessage(
              "Home Phone No is required...",
              required
            ),
          },
          mobileNum: {
            required: helpers.withMessage("Mobile No is required...", required),
          },
          email: {
            required: helpers.withMessage("Email is required...", required),
          },
          nidDoc: {
            required: helpers.withMessage("Expiry Date is required...", required),
          },
          employStatus: {
            required: helpers.withMessage("Status is required...", required),
          },
          user_phone_no: {
            required: helpers.withMessage(
              "User Phone No is required...",
              required
            ),
          },
          user_howlong: {
            required: helpers.withMessage("This is required...", required),
          },
          user_occupation: {
            required: helpers.withMessage("Occupation is required...", required),
          },
          user_w_phone_no: {
            required: helpers.withMessage(
              "Work Phone No is required...",
              required
            ),
          },
          user_employer: {
            required: helpers.withMessage("Address is required...", required),
          },
          employer: {
            required: helpers.withMessage("Employer is required...", required),
          },
          user_isstudent: {
            required: helpers.withMessage("School is required...", required),
          },
          frequencyStatus: {
            required: helpers.withMessage("Frequency is required...", required),
          },
          averageIncm: {
            required: helpers.withMessage(
              "Monthly Income is required...",
              required
            ),
          },
          accFunded: {
            required: helpers.withMessage("Fund is required...", required),
          },
          accountActi: {
            required: helpers.withMessage("This is required...", required),
          },
          accountActiDesc: {
            required: helpers.withMessage("This is required...", required),
          },
          numTrans: {
            required: helpers.withMessage("Transaction is required...", required),
          },
          volTrans: {
            required: helpers.withMessage("Transaction is required...", required),
          },
          account_one: {
            required: helpers.withMessage(
              "Account Number is required...",
              required
            ),
          },
          account_two: {
            required: helpers.withMessage(
              "Account Number is required...",
              required
            ),
          },
          account_three: {
            required: helpers.withMessage(
              "Account Number is required...",
              required
            ),
          },
          shares_amount: {
            required: helpers.withMessage("Amount is required...", required),
          },
          special_shares_amount: {
            required: helpers.withMessage("Amount is required...", required),
          },
          deposit_ac_amount: {
            required: helpers.withMessage("Amount is required...", required),
          },
          reg_fee_amount: {
            required: helpers.withMessage("Amount is required...", required),
          },
          total_amount: {
            required: helpers.withMessage("Amount is required...", required),
          },
          appointnominee_name1: {
            required: helpers.withMessage("Name is required...", required),
          },
          appointnominee_pno1: {
            required: helpers.withMessage("Phone No is required...", required),
          },
          appointnominee_rel1: {
            required: helpers.withMessage("Relation is required...", required),
          },
          appointnominee_address1: {
            required: helpers.withMessage("Address is required...", required),
          },
          appointnominee_email1: {
            required: helpers.withMessage("Email is required...", required),
          },
          appointnominee_dob1: {
            required: helpers.withMessage(
              "Date of Birth is required...",
              required
            ),
          },

          appointnominee_name2: {
            required: helpers.withMessage("Name is required...", required),
          },
          appointnominee_pno2: {
            required: helpers.withMessage("Phone No is required...", required),
          },
          appointnominee_rel2: {
            required: helpers.withMessage("Relation is required...", required),
          },
          appointnominee_address2: {
            required: helpers.withMessage("Address is required...", required),
          },
          appointnominee_email2: {
            required: helpers.withMessage("Email is required...", required),
          },
          appointnominee_dob2: {
            required: helpers.withMessage(
              "Date of Birth is required...",
              required
            ),
          },
          hereRadioType: {
            required: helpers.withMessage("Required...", required),
          },
          // appliname: {
          //   required: helpers.withMessage("Applicant name is required...", required),
          // },
          datename8: {
            required: helpers.withMessage("Date is required...", required),
          },
          // appliname8: {
          //   required: helpers.withMessage("ECU name is required...", required),
          // },
          entrustedRadio: {
            required: helpers.withMessage("Required...", required),
          },
          memberOfRadio: {
            required: helpers.withMessage("Required...", required),
          },
          referredRadio: {
            required: helpers.withMessage("Required...", required),
          },
          tobagoRadio: {
            required: helpers.withMessage("Required...", required),
          },
          citizenRadio: {
            required: helpers.withMessage("Required...", required),
          },
          ssn_no: {
            required: helpers.withMessage("SSN no is  required...", required),
          },
          addressRadio: {
            required: helpers.withMessage("Required...", required),
          },
          incomeUsRadio: {
            required: helpers.withMessage("Required...", required),
          },
          hereRadio1: {
            required: helpers.withMessage("Required...", required),
          },
          trusteeRadioo: {
            required: helpers.withMessage("Required...", required),
          },
          relationbenifit3: {
            required: helpers.withMessage(
              "Relationship is required...",
              required
            ),
          },
          appointnominee_name3: {
            required: helpers.withMessage("Name is required...", required),
          },
          appointnominee_pno3: {
            required: helpers.withMessage("Phone No is required...", required),
          },
          appointnominee_email3: {
            required: helpers.withMessage("Email is required...", required),
          },
          appointnominee_dob3: {
            required: helpers.withMessage(
              "Date of Birth is required...",
              required
            ),
          },
          appointnominee_address3: {
            required: helpers.withMessage("Address is required...", required),
          },
        },
      },
      // agreeAccept: {
      //   checked: value => value === true,
      // }
    },
    components: {
      FormWizard,
      TabContent,
      Layout,
      Select2,
      //   beforeRouteLeave(to, from, next) {
      //   // Save the form data to a reactive property or emit an event to the parent component
      //   this.$emit('saveFormData', this.form);
      //   next();
      // }
    },

    deactivated() {
      console.log("deactivate");
    },

    methods: {
      handleNext() {
        // Proceed to the next tab
        // this.$refs.myWizard.nextTab();
        // Scroll to the top of the page
        this.scrollToTop();
      },
      scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
      // downloadPDF() {
      //   try {
      //     // Create a new jsPDF instance
      //     const pdf = new jsPDF();

      //     // Add content to the PDF document
      //     pdf.text("Your data goes here", 10, 10);

      //     // Save the PDF
      //     pdf.save("output.pdf");
      //   } catch (error) {
      //     console.error("Error converting to PDF:", error);
      //   }
      // },
      downloadPDF() {
        this.$router.push({ name: "PDF Application" });
        // var t = localStorage.getItem("accessToken");
        // var app = window.location.href;
        // const segments = app.split("/");
        // const app_id = segments[segments.length - 1];
        // // Create a new window
        // axios
        //   .post("applications/view_application/", {
        //     headers: {
        //       token: `Bearer ${t}`,
        //       app_id: app_id,
        //     },
        //   })
        //   .then((response) => {
        //     // Handle the API response
        //     console.log(response.data);
        //   })
        //   .catch((error) => {
        //     console.error(error);
        //   });

        // let mywindow = window.open("", "PRINT", "height=400,width=600");

        // // Get the HTML content of the form
        // let formContent = document.getElementById("pdf_data_form").innerHTML;

        // // Write the form content to the new window
        // mywindow.document.write(formContent);

        // // Close the document after writing
        // mywindow.document.close();

        // // Focus on the new window
        // mywindow.focus();

        // // Print the new window
        // mywindow.print();

        // // Close the new window
        // mywindow.close();

        // return true;
      },
      getIssueImageUrl(app_id) {
        axios
          .get(`/applications/issue_image/${app_id}/`)
          .then((response) => {
            console.log(" getIssueImageUrl of API Response:", response.data);
            const imageUrl = response.data.url || null;
            const imageUrl_two = response.data.url_two || null;
            const imageUrl_three = response.data.url_three || null;
            const imageUrl_four = response.data.url_four || null;
            const imageUrl_five = response.data.url_five || null;
            const imageUrl_six = response.data.url_six || null;
            const imageUrl_seven = response.data.url_seven || null;
            const imageUrl_eight = response.data.url_eight || null;
            const imageUrl_nine = response.data.url_nine || null;

            if (imageUrl !== null) {
              this.imageUrl = imageUrl;
            }
            if (imageUrl_two !== null) {
              this.imageUrl_two = imageUrl_two;
            }
            if (imageUrl_three !== null) {
              this.imageUrl_three = imageUrl_three;
            }
            if (imageUrl_four !== null) {
              this.imageUrl_four = imageUrl_four;
            }
            if (imageUrl_five !== null) {
              this.imageUrl_five = imageUrl_five;
            }
            if (imageUrl_six !== null) {
              this.imageUrl_six = imageUrl_six;
            }
            if (imageUrl_seven !== null) {
              this.imageUrl_seven = imageUrl_seven;
            }
            if (imageUrl_eight !== null) {
              this.imageUrl_eight = imageUrl_eight;
            }
            if (imageUrl_nine !== null) {
              this.imageUrl_nine = imageUrl_nine;
            }
          })
          .catch((error) => {
            console.error("Error fetching issue image:", error);
          });
      },
      submitFeedback() {
        console.log("ooooooooooooooooooooooooooooooooo");
        var t = localStorage.getItem("accessToken");
        var app = window.location.href;
        const segments = app.split("/");
        const app_id = segments[segments.length - 1];
        if (this.selectStatusPro !== "select") {
          this.submitting = true;
          this.showModal = false;
          axios
            .post(`/applications/submit_feedback/`, {
              token: t,
              app_id: app_id,
              section: this.incompleteAppStatus,
              com: this.incompleteAppComment,
              status: this.selectStatusPro,
            })
            .then((response) => {
              console.log("response________________:", response);

              this.selectStatusPro = "submitReview";
              this.incompleteAppComment = "";
              this.incompleteAppStatus = "select";
              this.$router.push({ name: "application list" });
            })
            .catch((error) => {
              console.error("Error sending email:", error);
            });
        }
      },
      duplicateItem() {
        this.itemsss.push(Date.now());
      },

      onComplete() {
        console.log(this.form.personal.checkListOffice1);
        if (
          this.form.personal.checkListOffice1 === true &&
          this.form.personal.checkListOffice2 === true &&
          this.form.personal.checkListOffice3 === true &&
          this.form.personal.checkListOffice4 === true &&
          this.form.personal.checkListOffice5 === true &&
          this.form.personal.checkListOffice6 === true
        ) {
          // alert("Yay All Ticked & done");
          window.location.href = "/application_list";
        } else {
          // alert("Something Went Wrong");
          window.location.href = "/application_list";
        }
      },
      chkStaff() {
        var t = localStorage.getItem("userType");
        var tok = localStorage.getItem("accessToken");
        var toke = "";
        if (
          window.location.href.includes("review_application") ||
          window.location.href.includes("view_application")
        ) {
          toke = tok;
          this.showOfficial = true;
        } else {
          toke = null;
        }
        var app = window.location.href;
        const segments = app.split("/");
        const app_id = segments[segments.length - 1];
        // Create a new Date object with today's date
        var today = new Date();

        // Format the date as YYYY-MM-DD (or any other desired format)
        var formattedDate = today.toISOString().split("T")[0];
        if (t === "staff" || t === "verification officer") {
          axios
            .get(`/applications/single_application_details/${app_id}`, {
              params: { token: toke },
            })
            .then((response) => {
              // Handle the response
              this.reviewerName = response.data.app_reviewer;
              this.reviewDate = formattedDate;
              this.reviewApprovedDate = formattedDate;
              this.imageSrcStamp = response.data.app_reviewer_stamp;
              this.imageSrcSig = response.data.app_reviewer_sign;
              console.log("Initial DATA SUBMIT successfully:", response.data);
              if (response.data.app_data.is_adult === true) {
                console.log("INNNNER IIIFFIFIF");
                this.radioSelected1 = "adult";
              } else {
                this.radioSelected1 = "minor";
              }
              // if (response.data.app_data.section_f6.other_citizen === true) {
              //   console.log("INNNNER if condition ");
              //   this.form.personal.tobagoRadio = "True";
              // } else {
              //   console.log("INNNNER else condition ");
              //   this.form.personal.tobagoRadio = "False";
              // }

              // if (response.data.app_data.is_fatca === true) {
              //   this.checkFeildInput2 = true;
              // }
              // if (response.data.app_data.is_pep === true) {
              //   this.checkFeildInput1 = true;
              // }
              // if (response.data.app_data.risk_cat) {
              //   this.riskInput = response.data.app_data.risk_cat;
              // }
              if (response.data.app_data.section_a1) {
                this.form.personal.branchName =
                  response.data.app_data.section_a1.branch;
                // this.form.personal.personNum = response.data.app_data.section_a1.person_no;
                // this.form.personal.memberNum = response.data.app_data.section_a1.member_no;
                this.form.personal.title =
                  response.data.app_data.section_a1.applicant_title;
                this.form.personal.firstName =
                  response.data.app_data.section_a1.first_name;
                this.form.personal.middleName =
                  response.data.app_data.section_a1.middle_name;
                this.form.personal.surname =
                  response.data.app_data.section_a1.sur_name;
                this.form.personal.address =
                  response.data.app_data.section_a1.home_address;
                // this.form.personal.geoCode   = response.data.app_data.section_a1.geo_code;
                // this.form.personal.mailingAddress = response.data.app_data.section_a1.mail_address;
                ////////////////////////////////////////////////////////////////
                this.form.personal.address_line =
                  response.data.app_data.section_a1.address_line;
                this.form.personal.address_line_two =
                  response.data.app_data.section_a1.address_line_two;
                this.form.personal.city_name =
                  response.data.app_data.section_a1.city_name;
                this.form.personal.state_name =
                  response.data.app_data.section_a1.state_name;
                this.form.personal.zip_code =
                  response.data.app_data.section_a1.zip_code;
                this.form.personal.country_name =
                  response.data.app_data.section_a1.country_name;
                /////////////////////////////////////////////////////////////////////////////////////////////
                this.form.personal.dob = response.data.app_data.section_a1.dob;
                this.form.personal.gender =
                  response.data.app_data.section_a1.gender;
                this.form.personal.numOfDependents =
                  response.data.app_data.section_a1.dependents;
                this.form.personal.placeOfdob =
                  response.data.app_data.section_a1.place_of_birth;
                this.form.personal.nationality =
                  response.data.app_data.section_a1.nationality;
                this.form.personal.maritalStatus =
                  response.data.app_data.section_a1.marital;
                this.form.personal.nationalIdNum =
                  response.data.app_data.section_a1.nat_identity.number;
                this.form.personal.nidIssueDate =
                  response.data.app_data.section_a1.nat_identity.issue_on;
                this.form.personal.nidExpiry =
                  response.data.app_data.section_a1.nat_identity.expire_on;
                this.form.personal.nationalIssuance =
                  response.data.app_data.section_a1.nat_identity.isuue_country;
                this.form.personal.drivingIdNum =
                  response.data.app_data.section_a1.driver.number;
                this.form.personal.didIssueDate =
                  response.data.app_data.section_a1.driver.issue_on;
                this.form.personal.didExpiry =
                  response.data.app_data.section_a1.driver.expire_on;
                this.form.personal.driverIssuance =
                  response.data.app_data.section_a1.driver.isuue_country;
                this.form.personal.passportNum =
                  response.data.app_data.section_a1.passport.number;
                this.form.personal.passIssueDate =
                  response.data.app_data.section_a1.passport.issue_on;
                this.form.personal.passExpiry =
                  response.data.app_data.section_a1.passport.expire_on;
                this.form.personal.passportIssuance =
                  response.data.app_data.section_a1.passport.isuue_country;
                this.form.personal.birthIdNum =
                  response.data.app_data.section_a1.birth_cert.number;
                this.form.personal.birthIssuance =
                  response.data.app_data.section_a1.birth_cert.issue_on;
                this.form.personal.homePhoneNum =
                  response.data.app_data.section_a1.home_contact;
                this.form.personal.mobileNum =
                  response.data.app_data.section_a1.mob_contact;
                this.form.personal.email =
                  response.data.app_data.section_a1.email;
              }
              if (response.data.app_data.section_b2) {
                this.form.personal.employStatus =
                  response.data.app_data.section_b2.emp_status;
                this.form.personal.frequencyStatus =
                  response.data.app_data.section_b2.pay_freq;
                this.form.personal.user_howlong =
                  response.data.app_data.section_b2.emp_time;
                this.form.personal.averageIncm =
                  response.data.app_data.section_b2.avg_mnth_income;
                this.form.personal.user_occupation =
                  response.data.app_data.section_b2.occupation;
                this.form.personal.employer =
                  response.data.app_data.section_b2.employer;
                this.form.personal.user_employer =
                  response.data.app_data.section_b2.emp_address;
                this.form.personal.user_w_phone_no =
                  response.data.app_data.section_b2.work_contact;

                this.form.personal.user_w_phone_nocode =
                  response.data.app_data.section_b2.ext_work_contact;

                this.form.personal.user_phone_no =
                  response.data.app_data.section_b2.extra_ext_work;

                this.form.personal.user_isstudent =
                  response.data.app_data.section_b2.school;
              }
              if (response.data.app_data.section_c3) {
                this.form.personal.accFunded =
                  response.data.app_data.section_c3.fund_source;
                this.form.personal.accountActi =
                  response.data.app_data.section_c3.mem_in_others;
                this.form.personal.accountActiDesc =
                  response.data.app_data.section_c3.mem_in_others_desc;
                this.form.personal.socuceofincome =
                  response.data.app_data.section_c3.socuce_of_income;
                this.form.personal.areaofdetails =
                  response.data.app_data.section_c3.area_of_details;
                this.form.personal.numTrans =
                  response.data.app_data.section_c3.nos_trx;
                this.form.personal.volTrans =
                  response.data.app_data.section_c3.vol_trx;
              }
              if (response.data.app_data.section_d4) {
                this.form.personal.parentName =
                  response.data.app_data.section_d4.incare_g.name;
                this.form.personal.parentId =
                  response.data.app_data.section_d4.incare_g.identification;
                this.form.personal.trusteeName =
                  response.data.app_data.section_d4.incare_t.name;
                this.form.personal.trusteeId =
                  response.data.app_data.section_d4.incare_t.identification;
                this.form.personal.advisor = response.data.app_data.section_d4
                  .advisor
                  ? response.data.app_data.section_d4.advisor
                  : "";
                this.form.personal.declaration =
                  response.data.app_data.section_d4.decleration;
              }
              if (response.data.app_data.section_e5) {
                this.form.personal.entrustedRadio =
                  response.data.app_data.section_e5.prom_entrust;
                this.form.personal.memberOfRadio =
                  response.data.app_data.section_e5.immd_famly;
                this.form.personal.referredRadio =
                  response.data.app_data.section_e5.close_prsonl;
              }
              if (response.data.app_data.section_f6) {
                this.form.personal.tobagoRadio =
                  response.data.app_data.section_f6.other_citizen;
                this.form.personal.citizenRadio =
                  response.data.app_data.section_f6.us_citizen;
                this.form.personal.ssn_no =
                  response.data.app_data.section_f6.ssn_no;
                this.form.personal.addressRadio =
                  response.data.app_data.section_f6.us_address;
                this.form.personal.incomeUsRadio =
                  response.data.app_data.section_f6.givin_instruct;
              }
              if (response.data.app_data.section_g7) {
                this.form.personal.account_one =
                  response.data.app_data.section_g7.shares_acc;
                this.form.personal.shares_amount =
                  response.data.app_data.section_g7.shares_amt;
                this.form.personal.account_two =
                  response.data.app_data.section_g7.spshares_ac;
                this.form.personal.special_shares_amount =
                  response.data.app_data.section_g7.spshares_amt;
                this.form.personal.account_three =
                  response.data.app_data.section_g7.deposit_ac;
                this.form.personal.deposit_ac_amount =
                  response.data.app_data.section_g7.deposit_amt;
                this.form.personal.reg_fee_amount =
                  response.data.app_data.section_g7.reg_fee;
                this.form.personal.total_amount =
                  response.data.app_data.section_g7.total;
              }
              if (response.data.app_data.section_h8) {
                this.form.personal.appointnominee_name1 =
                  response.data.app_data.section_h8.nominee_1.name;
                this.form.personal.appointnominee_pno1 =
                  response.data.app_data.section_h8.nominee_1.contact;
                this.form.personal.appointnominee_pno1code =
                  response.data.app_data.section_h8.nominee_1.ext_contact ??
                  "N/A";
                this.form.personal.appointnominee_rel1 =
                  response.data.app_data.section_h8.nominee_1.relation;
                this.form.personal.appointnominee_rel_details =
                  response.data.app_data.section_h8.nominee_1.other_relative;

                this.form.personal.appointnominee_address1 =
                  response.data.app_data.section_h8.nominee_1.address;
                this.form.personal.appointnominee_email1 =
                  response.data.app_data.section_h8.nominee_1.email_address;
                this.form.personal.appointnominee_dob1 =
                  response.data.app_data.section_h8.nominee_1.applicant_dob;

                this.form.personal.appointnominee_name2 =
                  response.data?.app_data?.section_h8?.nominee_2?.name ?? "N/A";
                this.form.personal.appointnominee_pno2 =
                  response.data?.app_data?.section_h8?.nominee_2?.contact ??
                  "N/A";

                this.form.personal.appointnominee_pno1code2 =
                  response.data?.app_data?.section_h8?.nominee_2?.ext_contact ??
                  "N/A";

                this.form.personal.appointnominee_rel2 =
                  response.data?.app_data?.section_h8?.nominee_2?.relation ??
                  "N/A";

                this.form.personal.appointnominee_rel2_details =
                  response.data?.app_data?.section_h8?.nominee_2?.other_relative ??
                  "N/A";


                this.form.personal.appointnominee_address2 =
                  response.data?.app_data?.section_h8?.nominee_2?.address ??
                  "N/A";
                this.form.personal.appointnominee_email2 =
                  response.data?.app_data?.section_h8?.nominee_2?.email_address ??
                  "N/A";
                this.form.personal.appointnominee_dob2 =
                  response.data?.app_data?.section_h8?.nominee_2?.applicant_dob ??
                  "N/A";

                this.form.personal.trusteeRadioo =
                  response.data?.app_data?.section_h8?.nominee_minor?.caretaker
                    ?.type ?? "N/A";
                this.form.personal.appointnominee_name3 =
                  response.data?.app_data?.section_h8?.nominee_minor?.name ??
                  "N/A";
                this.form.personal.appointnominee_pno3 =
                  response.data?.app_data?.section_h8?.nominee_minor?.contact ??
                  "N/A";
                this.form.personal.relationbenifit3 =
                  response.data?.app_data?.section_h8?.nominee_minor?.relation ??
                  "N/A";
                this.form.personal.relationbenifit3_relation =
                  response.data?.app_data?.section_h8?.nominee_minor?.other_relative ??
                  "N/A";


                this.form.personal.appointnominee_address3 =
                  response.data?.app_data?.section_h8?.nominee_minor?.address ??
                  "N/A";
                this.form.personal.appointnominee_email3 =
                  response.data?.app_data?.section_h8?.nominee_minor
                    ?.email_address ?? "N/A";
                this.form.personal.appointnominee_dob3 =
                  response.data?.app_data?.section_h8?.nominee_minor
                    ?.applicant_dob ?? "N/A";
              }

              this.form.personal.appliname = `${response.data.app_data.section_a1.first_name} ${response.data.app_data.section_a1.sur_name}`;
              if (response.data.app_data.section_i9) {
                this.form.personal.hereRadioType =
                  response.data.app_data.section_i9.decleration;
                this.form.personal.nameofparent =
                  response.data?.app_data?.section_d4?.incare_g.name ?? "N/A";
                this.form.personal.nameoftrustee =
                  response.data?.app_data?.section_d4?.incare_t.name ?? "N/A";
                // this.form.personal.appointnominee_pno1        = response.data.app_data.section_i9.declared_at;
              }
            })
            .catch((error) => {
              // Handle any errors
              console.error("Error sending email:", error);
            });
        } else {
          return true;
        }
      },
      viewApplication(app_id) {
        const t = localStorage.getItem("accessToken");
        console.log("this token is valid", t, app_id);
        axios
          .post("applications/view_application/", {
            headers: {
              token: `Bearer ${t}`,
              app_id: this.app_id,
            },
          })
          .then((response) => {
            // Handle the API response
            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
      },
      // onFileChange(event) {
      //   this.files = event.target.files;
      // },
      // resetFields() {
      //   this.showRadio = false;
      // },
      // validateFormOne() {
      //   this.v$.$touch();
      //   if (this.v$.form.personal.$invalid) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // },
      // previewImage(event, id) {
      //   console.log(id);
      //   var input = event.target;
      //   if (input.files) {
      //     var reader = new FileReader();
      //     reader.onload = (e) => {
      //       var data = {
      //         value: e.target.result,
      //         type: input.files[0].name.substring(
      //           input.files[0].name.lastIndexOf(".") + 1
      //         ),
      //       };

      //       this.preview[id] = data;
      //     };
      //     this.image[id] = input.files[0];
      //     console.log(
      //       input.files[0].name.substring(
      //         input.files[0].name.lastIndexOf(".") + 1
      //       )
      //     );
      //     reader.readAsDataURL(input.files[0]);
      //   }
      // },
      // submitDataInitial() {
      //   console.log("HELLO FROM SUBMIT DATA");
      //   var adult = "";
      //   // Make the API request with the entered email
      //   var t = localStorage.getItem("accessToken");
      //   console.log("EMAIL IS THIS radioSelected1", t);
      //   if (this.radioSelected1 === "adult") {
      //     adult = true;
      //   } else {
      //     adult = false;
      //   }
      //   console.log("Adult", adult);
      //   axios
      //     .post("/applications/initial_application_submit/", {
      //       token: t,
      //       is_adult: adult,
      //       is_fatca: this.checkFeildInput2,
      //       is_pep: this.checkFeildInput1,
      //       risk_cat: this.riskInput,
      //     })
      //     .then((response) => {
      //       // Handle the response
      //       console.log("Initial DATA SUBMIT successfully:", response.data.id);
      //       this.app_id = response.data.id;
      //       this.$refs.anotherButton.click();
      //       this.startIndex = 0;
      //     })
      //     .catch((error) => {
      //       // Handle any errors
      //       console.error("Error sending email:", error);
      //     });
      // },

      // fetchData() {
      //   var t = localStorage.getItem("accessToken");
      //   this.isLoading = true;
      //   axios
      //     .post("/applications/applicant_app_status/", { token: t })
      //     .then((response) => {
      //       if (response.data.app_data) {
      //         this.app_id = response.data.app_data.id;
      //         console.log("APP IDDDDD", this.app_id);
      //       }
      //       const status = response.data.app_status;
      //       console.log(
      //         "cheack the data came or not for application data ",
      //         status
      //       );
      //       if (status == "section0") {
      //         this.isLoading = false;
      //         this.showRadio = true;
      //         this.startIndex = 0;
      //         console.log(
      //           "cheack the data came or not for application data 222",
      //           response.data.app_data.is_adult
      //         );
      //         if (response.data.app_data.is_adult === true) {
      //           console.log("INNNNER IIIFFIFIF");
      //           this.radioSelected1 = "adult";
      //         } else {
      //           this.radioSelected1 = "minor";
      //         }
      //         if (response.data.app_data.is_fatca === true) {
      //           this.checkFeildInput2 = true;
      //         }
      //         if (response.data.app_data.is_pep === true) {
      //           this.checkFeildInput1 = true;
      //         }
      //         if (response.data.app_data.risk_cat) {
      //           this.riskInput = response.data.app_data.risk_cat;
      //         }
      //         console.log(this.radioSelected1);
      //       } else if (status == "section1") {
      //         this.isLoading = false;
      //         this.showRadio = true;
      //         this.startIndex = 1;
      //       } else if (status == "section2") {
      //         this.isLoading = false;
      //         this.showRadio = true;
      //         this.startIndex = 2;
      //         console.log(
      //           "cheack the data came or not for application data 222",
      //           response.data.app_data.is_adult
      //         );
      //         if (response.data.app_data.is_adult === true) {
      //           console.log("INNNNER IIIFFIFIF");
      //           this.radioSelected1 = "adult";
      //         } else {
      //           this.radioSelected1 = "minor";
      //         }
      //         if (response.data.app_data.is_fatca === true) {
      //           this.checkFeildInput2 = true;
      //         }
      //         if (response.data.app_data.is_pep === true) {
      //           this.checkFeildInput1 = true;
      //         }
      //         if (response.data.app_data.risk_cat) {
      //           this.riskInput = response.data.app_data.risk_cat;
      //         }
      //         if (response.data.user_occupation === "") {
      //           alert("fill the details");
      //           return;
      //         }
      //       } else if (status == "section3") {
      //         if (response.data.app_data.is_adult === true) {
      //           console.log("INNNNER IIIFFIFIF");
      //           this.radioSelected1 = "adult";
      //         } else {
      //           this.radioSelected1 = "minor";
      //         }
      //         if (response.data.app_data.is_fatca === true) {
      //           this.checkFeildInput2 = true;
      //         }
      //         if (response.data.app_data.is_pep === true) {
      //           this.checkFeildInput1 = true;
      //         }
      //         if (response.data.app_data.risk_cat) {
      //           this.riskInput = response.data.app_data.risk_cat;
      //         }
      //         console.log(this.radioSelected1);
      //         this.isLoading = false;
      //         this.showRadio = true;
      //         this.startIndex = 3;
      //       } else if (status == "section4") {
      //         this.isLoading = false;
      //         this.showRadio = true;
      //         this.startIndex = 4;
      //       } else if (status == "section5") {
      //         this.isLoading = false;
      //         this.showRadio = true;
      //         this.startIndex = 5;
      //       } else if (status == "section6") {
      //         this.isLoading = false;
      //         this.showRadio = true;
      //         this.startIndex = 6;
      //       } else if (status == "section7") {
      //         this.isLoading = false;
      //         this.showRadio = true;
      //         this.startIndex = 7;
      //       } else if (status == "section8") {
      //         this.isLoading = false;
      //         this.showRadio = true;
      //         this.startIndex = 8;
      //       } else if (status == "section9") {
      //         this.isLoading = false;
      //         this.showRadio = true;
      //         this.startIndex = 9;
      //       } else {
      //         this.isLoading = false;
      //         this.showRadio = false;
      //       }
      //     })
      //     .catch((error) => {
      //       console.error(error);
      //     });
      // },
      // submitDataPersonal() {
      //   console.log("HELLO FROM SUBMIT DATA Personal");
      //   var t = localStorage.getItem("accessToken");
      //   let r = false;
      //   this.form.personal.appliname = this.form.personal.firstName
      //   axios
      //     .post("/applications/personal_information_submit/", {
      //       token: t,
      //       app_id: this.app_id,
      //       branch: this.form.personal.branchName,
      //       member_no: this.form.personal.personNum,
      //       person_no: this.form.personal.memberNum,
      //       title: this.form.personal.title,
      //       surname: this.form.personal.surname,
      //       first_name: this.form.personal.firstName,
      //       middle_name: this.form.personal.middleName,
      //       home_add: this.form.personal.address,
      //       geo_code: this.form.personal.geoCode,
      //       mailing_add: this.form.personal.mailingAddress,
      //       dob: this.form.personal.dob,
      //       gender: this.form.personal.gender,
      //       dependents: this.form.personal.numOfDependents,
      //       place_of_birth: this.form.personal.placeOfdob,
      //       nationality: this.form.personal.nationality,
      //       marital_status: this.form.personal.maritalStatus,
      //       // National ID section
      //       national_id: this.form.personal.nationalIdNum,
      //       national_id_issue_date: this.form.personal.nidIssueDate,
      //       national_id_expiry_date: this.form.personal.nidExpiry,
      //       national_id_country: this.form.personal.nationalIssuance,
      //       national_id_doc: this.$refs.fileInput1,
      //       // Driving License Section
      //       driver_permit: this.form.personal.drivingIdNum,
      //       driver_permit_issue_date: this.form.personal.didIssueDate,
      //       driver_permit_expiry_date: this.form.personal.didExpiry,
      //       driver_permit_country: this.form.personal.driverIssuance,
      //       driver_permit_doc: this.$refs.fileInput2,
      //       // Passport Section
      //       passport_no: this.form.personal.passportNum,
      //       passport_no_issue_date: this.form.personal.passIssueDate,
      //       passport_no_expiry_date: this.form.personal.passExpiry,
      //       passport_no_country: this.form.personal.passportIssuance,
      //       passport_no_doc: this.$refs.fileInput3,
      //       // DOB certificate Section
      //       dob_no: this.form.personal.birthIdNum,
      //       dob_issue_country: this.form.personal.birthIssuance,
      //       dob_doc: this.$refs.fileInput4,

      //       home_mob_no: this.form.personal.homePhoneNum,
      //       mob_no: this.form.personal.mobileNum,
      //       email: this.form.personal.email,
      //     })
      //     .then((response) => {
      //       // Handle the response
      //       console.log("Personal Information Submitted Successfully:", response);
      //       r = true;
      //     })
      //     .catch((error) => {
      //       // Handle any errors
      //       console.error("Error sending email:", error);
      //     });
      //   return r;
      // },
      // submitDataEmployement() {
      //   console.log("HELLO FROM SUBMIT DATA EMPLOYEMENZT");
      //   var t = localStorage.getItem("accessToken");
      //   let r = false;
      //   console.log("HELLO FROM", this.form.personal.employStatus);
      //   axios
      //     .post("/applications/employment_information_submit/", {
      //       token: t,
      //       app_id: this.app_id,
      //       emp_status: this.form.personal.employStatus,
      //       pay_freq: this.form.personal.frequencyStatus,
      //       avg_monthly_inc: this.form.personal.averageIncm,
      //       occupation: this.form.personal.user_occupation,
      //       employer: this.form.personal.employer,
      //       employer_add: this.form.personal.user_employer,
      //       work_phone: this.form.personal.user_w_phone_no,
      //       ext: this.form.personal.user_phone_no,
      //       school: this.form.personal.user_isstudent,
      //       emp_period: this.form.personal.user_howlong,
      //     })
      //     .then((response) => {
      //       // Handle the response
      //       console.log("Employer Information Submitted Successfully:", response);
      //       r = true;
      //     })
      //     .catch((error) => {
      //       // Handle any errors
      //       console.error("Error sending email:", error);
      //     });
      //   return r;
      // },
      // submitDataAccount() {
      //   console.log("HELLO FROM SUBMIT DATA EMPLOYEMENZT");
      //   var t = localStorage.getItem("accessToken");
      //   let r = false;
      //   axios
      //     .post("/applications/account_activity_submit/", {
      //       token: t,
      //       app_id: this.app_id,
      //       how_account_funded: this.form.personal.accFunded,
      //       member_any_credit_union: this.form.personal.accountActi,
      //       member_any_credit_union_desc: this.form.personal.accountActiDesc,
      //       no_of_transactions: this.form.personal.numTrans,
      //       vol_of_transactions: this.form.personal.volTrans,
      //     })
      //     .then((response) => {
      //       // Handle the response
      //       console.log(
      //         "Employer Account Information Submitted Successfully:",
      //         response
      //       );
      //       r = true;
      //     })
      //     .catch((error) => {
      //       // Handle any errors
      //       console.error("Error sending email:", error);
      //     });
      //   return r;
      // },
      // submitDataMinor() {
      //   console.log("HELLO FROM SUBMIT DATA MINOR");
      //   var t = localStorage.getItem("accessToken");
      //   let r = false;
      //   axios
      //     .post("/applications/minors_only_submit/", {
      //       token: t,
      //       app_id: this.app_id,
      //       parent_name: this.form.personal.parentName,
      //       parent_id_no: this.form.personal.parentId,
      //       trustee: this.form.personal.trusteeName,
      //       trustee_id_no: this.form.personal.trusteeId,
      //       dec: this.form.personal.declaration,
      //       // witness:
      //     })
      //     .then((response) => {
      //       // Handle the response
      //       console.log(
      //         "Employer Account Information Submitted Successfully:",
      //         response
      //       );
      //       r = true;
      //     })
      //     .catch((error) => {
      //       // Handle any errors
      //       console.error("Error sending email:", error);
      //     });
      //   return r;
      // },
      // submitDataPEP() {
      //   console.log("HELLO FROM SUBMIT DATA MINOR PEP ------");
      //   var t = localStorage.getItem("accessToken");
      //   let r = false;
      //   axios
      //     .post("/applications/politically_exposed_persons/", {
      //       token: t,
      //       app_id: this.app_id,
      //       prom_entrust: this.form.personal.entrustedRadio,
      //       immd_famly: this.form.personal.memberOfRadio,
      //       close_prsonl: this.form.personal.referredRadio,
      //     })
      //     .then((response) => {
      //       // Handle the response
      //       console.log(
      //         "Employer Account Information Submitted Successfully PEP:",
      //         this.app_id,
      //         this.form.personal.entrustedRadio,
      //         this.form.personal.memberOfRadio,
      //         this.form.personal.referredRadio
      //       );
      //       console.log(
      //         "Employer Account Information Submitted Successfully PEP:",
      //         response
      //       );
      //       r = true;
      //     })
      //     .catch((error) => {
      //       // Handle any errors
      //       console.error("Error sending email:", error);
      //     });
      //   return r;
      // },
      // submitDataFATCA() {
      //   console.log("HELLO FROM SUBMIT DATA MINOR  FATCA ------");
      //   var t = localStorage.getItem("accessToken");
      //   let r = false;
      //   axios
      //     .post("/applications/foreign_account_tax/", {
      //       token: t,
      //       app_id: this.app_id,
      //       other_citizen: this.form.personal.tobagoRadio,
      //       us_citizen: this.form.personal.citizenRadio,
      //       ssn_no: this.form.personal.ssn_no,
      //       us_address: this.form.personal.addressRadio,
      //       givin_instruct: this.form.personal.incomeUsRadio,
      //     })
      //     .then((response) => {
      //       // Handle the response
      //       console.log(
      //         "Employer Account Information Submitted Successfully FATCA :",
      //         this.app_id,
      //         this.form.personal.tobagoRadio,
      //         this.form.personal.citizenRadio,
      //         this.form.personal.ssn_no,
      //         this.form.personal.addressRadio,
      //         this.form.personal.incomeUsRadio
      //       );
      //       console.log(
      //         "Employer Account Information Submitted Successfully FATCA :",
      //         response
      //       );
      //       r = true;
      //     })
      //     .catch((error) => {
      //       // Handle any errors
      //       console.error("Error sending email:", error);
      //     });
      //   return r;
      // },
      // submitData_accountandfees() {
      //   console.log("HELLO FROM SUBMIT DATA MINOR accountandfees ------");
      //   var t = localStorage.getItem("accessToken");
      //   let r = false;
      //   axios
      //     .post("/applications/account_and_fees/", {
      //       token: t,
      //       app_id: this.app_id,

      //       account_number_one: this.form.personal.account_one,
      //       account_number_two: this.form.personal.account_two,
      //       account_number_three: this.form.personal.account_three,
      //       shares_amount: this.form.personal.shares_amount,
      //       special_shares_amount: this.form.personal.special_shares_amount,
      //       deposit_ac_amount: this.form.personal.deposit_ac_amount,
      //       reg_fee_amount: this.form.personal.reg_fee_amount,
      //       total_amount: this.form.personal.total_amount,
      //     })
      //     .then((response) => {
      //       // Handle the response
      //       console.log(
      //         "Employer Account Information Submitted Successfully accountandfees:",
      //         this.app_id,
      //         this.form.personal.account_one,
      //         this.form.personal.account_two,
      //         this.form.personal.account_three,
      //         this.form.personal.shares_amount,
      //         this.form.personal.special_shares_amount,
      //         this.form.personal.deposit_ac_amount,
      //         this.form.personal.reg_fee_amount,
      //         this.form.personal.total_amount
      //       );
      //       console.log(
      //         "Employer Account Information Submitted Successfully accountandfees:",
      //         response
      //       );
      //       r = true;
      //     })
      //     .catch((error) => {
      //       // Handle any errors
      //       console.error("Error sending email:", error);
      //     });
      //   return r;
      // },
      // submitData_appointment_nominee() {
      //   console.log("HELLO FROM SUBMIT DATA MINOR  appointment_nominee ------");
      //   var t = localStorage.getItem("accessToken");
      //   let r = false;
      //   axios
      //     .post("/applications/appointment_of_nominee/", {
      //       token: t,
      //       app_id: this.app_id,

      //       appointnominee_name1: this.form.personal.appointnominee_name1,
      //       appointnominee_pno1: this.form.personal.appointnominee_pno1,
      //       appointnominee_rel1: this.form.personal.appointnominee_rel1,
      //       appointnominee_address1: this.form.personal.appointnominee_address1,
      //       appointnominee_gcode1: this.form.personal.appointnominee_gcode1,

      //       appointnominee_name2: this.form.personal.appointnominee_name2,
      //       appointnominee_pno2: this.form.personal.appointnominee_pno2,
      //       appointnominee_rel2: this.form.personal.appointnominee_rel2,
      //       appointnominee_address2: this.form.personal.appointnominee_address2,
      //       appointnominee_gcode2: this.form.personal.appointnominee_gcode2,

      //       // relationbenifit3:'',
      //       // appointnominee_name3:'',
      //       // appointnominee_pno3:'',
      //       // appointnominee_g_code3:'',
      //       // appointnominee_address3:'',
      //     })
      //     .then((response) => {
      //       // Handle the response
      //       console.log(
      //         "Employer Account Information Submitted Successfully appointment_nominee:",
      //         response
      //       );
      //       r = true;
      //     })
      //     .catch((error) => {
      //       // Handle any errors
      //       console.error("Error sending email:", error);
      //     });
      //   return r;
      // },
      // submitData_memberdec() {
      //   console.log("HELLO FROM SUBMIT DATA MINOR  appointment_nominee ------");
      //   var t = localStorage.getItem("accessToken");
      //   let r = false;
      //   axios
      //     .post("/applications/members_declaration/", {
      //       token: t,
      //       app_id: this.app_id,

      //       hereRadioType: this.form.personal.hereRadioType,
      //       datename8: this.form.personal.datename8,
      //       // appointnominee_rel1:this.form.personal.appointnominee_rel1,
      //       // appointnominee_address1:this.form.personal.appointnominee_address1,
      //       // appointnominee_gcode1:this.form.personal.appointnominee_gcode1,
      //     })
      //     .then((response) => {
      //       // Handle the response
      //       console.log(
      //         "Member declarations -----: ",
      //         this.form.personal.hereRadioType,
      //         this.form.personal.datename8
      //       );

      //       console.log(
      //         "Employer Account Information Submitted Successfully Member declarations:",
      //         response
      //       );
      //       r = true;
      //     })
      //     .catch((error) => {
      //       // Handle any errors
      //       console.error("Error sending email:", error);
      //     });
      //   return r;
      // },
      // incSlab() {
      //   this.isLoading = true;
      //   axios
      //     .get("/applications/avg_inc_slab_new/")
      //     .then((response) => {
      //       const status = response.data.inc_data;
      //       console.log(
      //         "cheack the data came or not for application  INCOME data ",
      //         status
      //       );
      //       this.optionsList = response.data.inc_data;
      //     })
      //     .catch((error) => {
      //       console.error(error);
      //     });
      // },
    },
  };
</script>
<style scoped>
  @media screen and (min-width: 1000px) {
    .floatbtns button {
      position: absolute;
      top: 20px;
      right: 30px;
    }

    .floatbtns {
      position: static;
    }
  }

  .floatbtns {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
  }
</style>